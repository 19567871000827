import { useState, useEffect } from 'react'
import { getUserStoreNameFromLocalStorage } from "../../helpers/localStorageHelper";
import Header from "../common/Header";
import PrepListGrid from "./PrepListGrid";
import { usePrepList } from "./hooks/usePrepList";
import './PrepList.css'
import { PrepArticle } from '../../shared/models/PrepList';
import _ from 'lodash';
import { useUpdatePrepList } from './hooks/useUpdatePrepList';


const PrepList = () => {
  const { data, currentDate, setCurrentDate } = usePrepList();
  const [prepList, setPrepList] = useState<PrepArticle[]>([])
  const { mutate: updatePrep } = useUpdatePrepList();

  useEffect(() => {
    if (!data || _.isEmpty(data)) {
      return;
    }
    setPrepList(data);
  }, [data]);

  const onDateChange = (newDate: Date) => setCurrentDate(newDate)
  
  var userStoreName = getUserStoreNameFromLocalStorage();
  const headerObject = {
    title: `SOUL ORIGIN (${userStoreName})`,
    subTitle: "Prep",
  };

  const onPrepItemChange = (item: PrepArticle, successCb?: () => void, errorCb?: () => void) => {
    const newList = [...prepList]
    const index = newList.findIndex(elem => elem.timeOfDay === item.timeOfDay && elem.ingredientPrepItem === item.ingredientPrepItem)

    if (index === -1) {
      return
    }

    newList[index] = item
    setPrepList(newList)
    updatePrep(item, {
      onSuccess: () => {
        if (successCb) {
          successCb();
        }
      },
      onError: () => {
        if (errorCb) {
          errorCb();
        }
      }
    })
  }

  return (
    <div className="so-prep-list row ml-lg-3 mt-md-3 text-left">
      <Header {...headerObject} />
      <PrepListGrid
        onDateChange={onDateChange}
        prepList={prepList}
        currentDate={currentDate}
        onPrepItemChange={onPrepItemChange}
      />
    </div>
  );
};

export default PrepList;
