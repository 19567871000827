import { useState } from 'react'
import { getWeek, getYear } from "date-fns";
import SearchBar from "material-ui-search-bar";
import { useEffect } from "react";
import { getWeekEndDate, getWeekStartDate } from "../../shared/util/date-utils";
import { useDeletePlanning } from "./hooks/useDeletePlanning";
import ActionMenu, { MENU_OPTIONS } from "./ActionMenu";
import { isAdmin, isFutureWeek } from "../../helpers/helper";
import CopyFromModal from './CopyFromModal';
import DeleteAllConfirmationModal from './DeleteAllConfirmationModal';

interface Props {
  selectedCategory: string;
  searchText: string;
  onSearchTextChange: (val: string) => void;
  weekStartDate: Date;
  onWeekChange: (change: 1 | -1) => void;
  openMenu: () => void;
  isMenuOpen: boolean;
  disabled: boolean;
}
const PlanningToolbar = ({
  selectedCategory,
  searchText,
  onSearchTextChange,
  weekStartDate,
  onWeekChange,
  isMenuOpen,
  openMenu,
  disabled
}: Props) => {
  const [activeMenuOption, setActiveMenuOption] = useState<string | null>(null);
  const {
    mutate: deleteWeekPlan,
    isLoading: deleteInProgress,
    isSuccess: deleteSuccess,
  } = useDeletePlanning();


  useEffect(() => {
    if (!deleteSuccess) {
      return;
    }

    window.location.reload();
  }, [deleteSuccess]);


  const handleDelete = () => {
    // send API call
    deleteWeekPlan({
      planWeek: getWeek(weekStartDate),
      planYear: getYear(weekStartDate),
    });
  };

  

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center p-2 pl-0 pb-3 mx-auto border-bottom">
        {selectedCategory && (
          <h4 className="md-h4 my-auto font-weight-bold flex-grow-1">
            {selectedCategory}
          </h4>
        )}

        <SearchBar
          value={searchText}
          className="w-25 ml-auto border shade-0 br-8"
          onChange={onSearchTextChange}
          onCancelSearch={() => onSearchTextChange("")}
        />

        {isFutureWeek(weekStartDate) && isAdmin() && (
          <div className="ml-3">
            <button
              className="btn btn-danger"
              type="button"
              onClick={handleDelete}
              disabled={deleteInProgress}
            >
              {deleteInProgress && (
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Delete Plan
            </button>
          </div>
        )}

        <div className="d-flex align-items-center mx-lg-2 mx-1">
          <button disabled={disabled} onClick={() => onWeekChange(-1)} className="bg-white border-0">
            <i className="fa-2x fa fa-angle-left" style={{ color: disabled ? 'lightgray' : 'black'}}></i>
          </button>
          <span className="mb-0 mx-2">
            {`${getWeekStartDate(weekStartDate)} - ${getWeekEndDate(
              weekStartDate
            )}`}
          </span>
          <button disabled={disabled} onClick={() => onWeekChange(1)} className="bg-white border-0">
            <i className="fa-2x fa fa-angle-right" style={{ color: disabled ? 'lightgray' : 'black'}}></i>
          </button>
        </div>

        <div className='planning-action-menu'>
          <i className="fa fa-ellipsis-h planning-action-menu__ellipsis" onClick={openMenu}></i>
          {isMenuOpen && <ActionMenu weekStartDate={weekStartDate} onSelectOption={(action) => setActiveMenuOption(action)} />}
        </div>
      </div>
      <CopyFromModal open={activeMenuOption === MENU_OPTIONS.COPY_FROM} onClose={() => setActiveMenuOption(null)} futureWeekDate={weekStartDate} />
      <DeleteAllConfirmationModal open={activeMenuOption === MENU_OPTIONS.DELETE_ALL} onClose={() => setActiveMenuOption(null)} />
    </div>
  );
};

export default PlanningToolbar;
