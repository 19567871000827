import * as React from "react";

export default function Button(props: any) {
  const appliedClass =
    (props.name === props.showValue ? "shade-1 " : "shade ") +
    "border-0 bg-white b-round bg-patern d-flex";
  return (
    <React.Fragment>
      <button
        onClick={() => props.onChangeBtnClick(props.name)}
        className={appliedClass}
      >
        <h4 className="m-auto font-weight-bold">{props.name}</h4>
      </button>
    </React.Fragment>
  );
}
