import { format, isToday, set } from "date-fns";
import { FocusEvent, useRef } from "react";
import { ITakeHomeSpecial } from "../../../shared/models/TakeHomeSpecial";
import ActionButtonNew from "../../common/ActionButtonNew";
import Notes from "../../common/Notes";
import { useUpdateTakeHomeNotes } from "../hooks/useUpdateTakeHomeNotes";

interface Props {
  product: ITakeHomeSpecial;
  onChange: (newProduct: ITakeHomeSpecial) => void;
  isDisabled: boolean;
  dateValue: Date;
}

const TakeHomeRow = ({ isDisabled, onChange, product, dateValue }: Props) => {
  const {mutate: updateTakeHomeNotes, isLoading: savingNotes} = useUpdateTakeHomeNotes();

  const timerRef = useRef<HTMLInputElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const inputRef = useRef<HTMLButtonElement>(null);

  const onFieldChange = (propertyName: keyof ITakeHomeSpecial, value: any) => {
    const newProduct = {
      ...product,
      isDirty: true,
      [propertyName]: value,
    };
    onChange(newProduct);
  };

  const onQuantityChange = (
    propertyName: "takeHome" | "waste",
    change: number
  ) => {
    const newValue = Math.max(0, product[propertyName] + change);
    onFieldChange(propertyName, newValue);
  };

  const onSaveNotes = (changedProduct: ITakeHomeSpecial, closeDialogCb: () => void) => {
    updateTakeHomeNotes(changedProduct, {
      onSuccess: (data: any) => {
        if (onChange) {
          onChange({ ...changedProduct, id: data });
        }

        closeDialogCb();
      },
      onError: () => {
        closeDialogCb();
      }
    })
  };

  const runOutClicked = () => {
    const today = new Date();
    const time = format(today, "HH:mm");

    if (labelRef.current !== null && inputRef.current !== null) {
      labelRef.current.innerText = time;
      inputRef.current.style.display = "none";
      labelRef.current.style.display = "block";
    }

    onFieldChange("runoutTime", today);
  };

  const displayTimer = () => {
    if (
      isToday(dateValue) &&
      labelRef.current !== null &&
      timerRef.current !== null
    ) {
      labelRef.current.style.display = "none";
      timerRef.current.style.display = "block";
      timerRef.current.value = labelRef.current.innerText;
    }
  };

  const changeTimer = (e: FocusEvent<HTMLInputElement>) => {
    if (timerRef.current !== null && labelRef.current !== null) {
      labelRef.current.innerText = timerRef.current.value;
      timerRef.current.style.display = "none";
      labelRef.current.style.display = "block";

      const [hours, minutes] = timerRef.current.value.split(":");
      const newDate = set(new Date(), {
        hours: +hours,
        minutes: +minutes,
      });

      onFieldChange("runoutTime", newDate);
    }
  };

  return (
    <div className="mx-1 row shade-row my-0 py-2 rounded mt-3 table-font">
      <div className="col-2 my-auto">
        <p className="mb-0">{product.productName}</p>
      </div>
      <div className="col-1 my-auto d-flex justify-content-center px-0">
        <button
          className="bg.-sec ctm-btn"
          ref={inputRef}
          style={{
            display: product.runoutTime ? "none" : "block",
            borderRadius: "5px",
            backgroundColor: "#FFB81C",
            color: "White",
            width: "80px",
            opacity: isDisabled ? "0.5" : "1"
          }}
          onClick={runOutClicked}
          id={`${product.productId}_runout_button`}
          value={"00:00"}
          disabled={isDisabled}
        >
          Run Out
        </button>
        <label
          style={{
            display: product.runoutTime ? "block" : "none",
          }}
          onClick={displayTimer}
          id={`${product.productId}_runout_label`}
          ref={labelRef}
        >
          {product.runoutTime
            ? format(new Date(product.runoutTime), "HH:mm")
            : "00:00"}
        </label>

        <input
          style={{ display: "none", width: "100px" }}
          type="time"
          onBlur={changeTimer}
          id={`${product.productId}_runout_input`}
          ref={timerRef}
          title=""
        ></input>
      </div>
      <div className="col-5 col-lg-4 col-xl-5 my-auto text-center">
        <div className="row flex-column flex-lg-row align-items-center justify-content-center">
          <ActionButtonNew
            colorClass="ctm-btn col-lg bg-sec"
            isDisabled={isDisabled || product.uom !== "Tray"}
            label={"-0.5"}
            onClick={() => onQuantityChange("takeHome", -0.5)}
          />

          <ActionButtonNew
            colorClass="ctm-btn col-lg bg-sec"
            isDisabled={isDisabled}
            label={"-1"}
            onClick={() => onQuantityChange("takeHome", -1)}
          />
          <span
            className={`col-lg ctm-btn py-1 mr-2`}
            style={{maxWidth: "45px"}}
          >
            {product.takeHome}
          </span>
          <ActionButtonNew
            colorClass="ctm-btn col-lg bg-org text-white"
            isDisabled={isDisabled || product.uom !== "Tray"}
            label={"+0.5"}
            onClick={() => onQuantityChange("takeHome", 0.5)}
          />
          <ActionButtonNew
            colorClass="ctm-btn col-lg bg-org text-white"
            isDisabled={isDisabled}
            label={"+1"}
            onClick={() => onQuantityChange("takeHome", 1)}
          />
          <ActionButtonNew
            colorClass="ctm-btn col-lg bg-org text-white"
            isDisabled={isDisabled}
            label={"+5"}
            onClick={() => onQuantityChange("takeHome", 5)}
          />
        </div>
      </div>
      <div className="col-3 col-lg-4 col-xl-3 my-auto text-center">
        <div className="row flex-column flex-lg-row align-items-center justify-content-center">
          <ActionButtonNew
            colorClass="ctm-btn col-lg bg-sec"
            isDisabled={isDisabled || product.uom !== "Tray"}
            label={"-0.5"}
            onClick={() => onQuantityChange("waste", -0.5)}
          />
          <ActionButtonNew
            colorClass="ctm-btn col-lg bg-sec"
            isDisabled={isDisabled}
            label={"-1"}
            onClick={() => onQuantityChange("waste", -1)}
          />
          <span
            className={`col-lg ctm-btn py-1 mr-2`}
            style={{maxWidth: "45px"}}
          >
            {product.waste}
          </span>
          <ActionButtonNew
            colorClass="ctm-btn col-lg bg-org text-white"
            isDisabled={isDisabled || product.uom !== "Tray"}
            label={"+0.5"}
            onClick={() => onQuantityChange("waste", 0.5)}
          />
          <ActionButtonNew
            colorClass="ctm-btn col-lg bg-org text-white"
            isDisabled={isDisabled}
            label={"+1"}
            onClick={() => onQuantityChange("waste", 1)}
          />
        </div>
      </div>
      <div className="col-1 text-center  my-auto">
        <Notes product={product} handleSubmit={onSaveNotes} savingNotesInProgess={savingNotes} />
      </div>
    </div>
  );
};

export default TakeHomeRow;
