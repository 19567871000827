import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { getHeaders } from "../../../helpers/helper";
import axiosInstance from "../../../shared/config/axiosInstance";
import { MutationKeys } from "../../../shared/react-query/mutationKeys";
import { ProductPrepItemDto } from "../../../shared/models/ProductPrepItems";

async function updateProductPrepItems(
  reqBody: ProductPrepItemDto,
  getAccessTokenSilently: any
) {
  const headers = await getHeaders(getAccessTokenSilently);

  const {data} = await axiosInstance.post("admin/SetProductPrepItems", reqBody, {
    headers: { ...headers, "Content-Type": "application/json" },
  });

  return data;
}

export function useUpdateProductPrepItems() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(
    (data: ProductPrepItemDto) =>
      updateProductPrepItems(data, getAccessTokenSilently),
    {
      mutationKey: [MutationKeys.PRODUCT_PREP_ITEMS],
    }
  );
}
