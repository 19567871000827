import React, { useState } from "react";
import { ITakeHomeSpecial } from "../../../shared/models/TakeHomeSpecial";
import ToolBar from "../../common/ToolBar";
import TakeHomeRows from "./TakeHomeRows";

interface Props {
  takeHomeSpecialsData: ITakeHomeSpecial[];
  selectedCategory: string;
  prodDate: Date;
  setProdDate: (newDate: Date) => void;
  onProductChange: (newProduct: ITakeHomeSpecial) => void;
}

const TakeHomeGrid = ({
  takeHomeSpecialsData,
  selectedCategory,
  prodDate,
  setProdDate,
  onProductChange,
}: Props) => {
  const [searchText, setSearchText] = useState("");
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll((prevState) => !prevState);
  };

  const getFilteredData = () => {
    let filtered = [...takeHomeSpecialsData];

    if (!showAll) {
      filtered = filtered.filter((product) => product.producedStock !== 0);
    }

    if (searchText) {
      filtered = filtered.filter((product) =>
        product.productName.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (selectedCategory) {
      filtered = filtered.filter(
        (product) => product.productCategory === selectedCategory
      );
    }

    return filtered;
  };

  return (
    <div className="w-100 mt-5 mx-auto">
      <div className="m-lg-3 mx-0 row t-shade">
        <div className="bg-white">
          <ToolBar
            selectedCategory={selectedCategory}
            hasSearch
            searchText={searchText}
            onSearchTextChange={(val: string) => setSearchText(val)}
            productionDate={prodDate}
            setProductionDate={setProdDate}
            hasShowAll
            showAll={showAll}
            toggleShowAll={toggleShowAll}
          />

          <TakeHomeRows
            data={getFilteredData()}
            dateValue={prodDate}
            onChange={onProductChange}
          />
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default TakeHomeGrid;
