import { useState } from "react";
import { format } from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";

import { Product } from "../../../shared/models/Product";
import { QueryKeys } from "../../../shared/react-query/queryKeys";
import axiosInstance from "../../../shared/config/axiosInstance";
import { getHeaders } from "../../../helpers/helper";

async function fetchProductionData(
  reqDate: Date,
  getAccessTokenSilently: any
): Promise<Product[]> {
  // axios request to fetch
  const headers = await getHeaders(getAccessTokenSilently);
  const fetchDate = format(reqDate, "yyyy-MM-dd");

  const { data } = await axiosInstance.get("/producestock", {
    params: {
      produceStockDate: fetchDate,
    },
    headers: headers,
  });
  return data;
}

export function useProduction() {
  const [productionDate, setProductionDate] = useState(new Date());
  const { getAccessTokenSilently } = useAuth0();

  const { data = [] } = useQuery(
    [QueryKeys.PRODUCTION, format(productionDate, "yyyy-MM-dd")],
    () => fetchProductionData(productionDate, getAccessTokenSilently)
  );

  return { data, productionDate, setProductionDate };
}
