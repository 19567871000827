import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { getHeaders } from "../../../helpers/helper";
import axiosInstance from "../../../shared/config/axiosInstance";
import { Product } from "../../../shared/models/Product";
import { MutationKeys } from "../../../shared/react-query/mutationKeys";

async function updateProduction(
  updatedProduction: Product[],
  getAccessTokenSilently: any
) {
  const headers = await getHeaders(getAccessTokenSilently);
  const {data} = await axiosInstance.post("/producestock", updatedProduction, {
    headers: {...headers, 'Content-Type': 'application/json'},
  });
  return data;
}

export function useUpdateProduction() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation((data: Product[]) => updateProduction(data, getAccessTokenSilently), {
    mutationKey: [MutationKeys.PRODUCTION]
  })

}
