import * as React from "react";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "../common/Header";
import DatePicker from "../common/DatePicker";
import ReportTable from "../common/ReportTable";
import { Button } from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import { remove_duplicates_es6, NonAdminRedirect } from "../../helpers/helper";
import { Loading } from "../Loading";
import { storesWithNoActuals } from "../../handlers/reports";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import moment from "moment";
import { dummyDataBBDR } from "../../helpers/mockData";
import { getUserStoreNameFromLocalStorage } from "../../helpers/localStorageHelper";

const API = process.env.REACT_APP_AUDIENCE || 3001;
export function StoreWithNoActuals() {
  NonAdminRedirect();
  const noData: any = [];
  const { getAccessTokenSilently } = useAuth0();
  let currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 11);
  const [reportData, setReportData] = useState([]);
  const [databackUp, setDataBackup]: any = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [productData, setProductData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [fromSelectedDate, setFromSelectedDate] = React.useState(new Date());
  const [minFromSelectedDate, setMinFromSelectedDate] =
    React.useState(currentDate);
  const [minToSelectedDate, setMinToSelectedDate] = React.useState(currentDate);
  const [maxFromSelectedDate, setMaxFromSelectedDate] = React.useState(
    new Date()
  );
  const [maxToSelectedDate, setMaxToSelectedDate] = React.useState(new Date());
  const [product, setProduct] = useState("");
  const [csvData, setCSVData] = useState([]);
  const [group, setGroup] = useState("");
  const [msg, setMsg] = useState("");
  const [toSelectedDate, setToSelectedDate] = React.useState(new Date());
  const [state, setState] = useState({
    error: null,
    loading: false,
  });
  const handleFromDateChange = (date: any) => {
    let enteredDate = new Date(date);
    enteredDate.setMonth(enteredDate.getMonth() + 1);
    setFromSelectedDate(date);
    setMinToSelectedDate(date);
    if (
      moment(enteredDate).format("YYYY-MM-DD") <=
      moment(new Date()).format("YYYY-MM-DD")
    ) {
      setMaxToSelectedDate(enteredDate);
      setToSelectedDate(enteredDate);
    } else {
      setMaxToSelectedDate(new Date());
      setToSelectedDate(new Date());
    }
  };
  const handleToDateChange = (date: any) => {
    let enteredDate = new Date(date);
    enteredDate.setMonth(enteredDate.getMonth());
    setToSelectedDate(date);
  };
  const getProductList = (dat: any) => {
    let productList: any = [];
    if (dat && dat.length) {
      productList = dat.map((prod: any) => {
        return prod.product;
      });
    }
    const uniq: any = remove_duplicates_es6(productList);
    setProductData(uniq);
  };
  const getGroupList = (dat: any) => {
    let groupList: any = [];
    if (dat && dat.length) {
      groupList = dat.map((prod: any) => {
        return prod.group;
      });
    }
    const uniq: any = remove_duplicates_es6(groupList);
    setGroupData(uniq);
  };
  const getCSVData = (dat: any) => {
    let csvData: any = [];
    if (dat && dat.length) {
      csvData = dat.map((prod: any) => {
        return {
          ...prod,
          buildDate: moment(prod.buildDate).format("DD/MM/YYYY"),
        };
      });
    }
    const uniq: any = remove_duplicates_es6(csvData);
    setCSVData(uniq);
  };
  const generateReport = async () => {
    try {
      setState({ ...state, error: null, loading: true });
      let dat = await storesWithNoActuals(
        fromSelectedDate,
        toSelectedDate,
        getAccessTokenSilently
      );
      if (!dat || (dat && dat.length === 0)) {
        setReportData([]);
        setDataBackup([]);
        setMsg("No Records Found.");
      }
      setReportData(dat);
      setDataBackup(dat);
      getProductList(dat);
      getGroupList(dat);
      getCSVData(dat);
      setState({ ...state, error: null, loading: false });
    } catch (error:any) {
      setReportData([]);
      setDataBackup([]);
      setState({ ...state, error, loading: false });
    }
  };
  const onSearchData = (value: string) => {
    if (value == "") {
      setReportData(databackUp);
      return;
    }
    const lowercasedFilter = value.toLowerCase();
    const result = databackUp.filter((el: any) =>
      el.storeName.toLowerCase().includes(lowercasedFilter)
    );
    var uniq: any = remove_duplicates_es6(result);
    setReportData(uniq);
    //todo
  };
  const onClearSearch = () => {
    setSearchValue("");
    setReportData(databackUp);
    //todo
  };

  if (state.loading) {
    return <Loading />;
  }
  const headers = ["Store", "Date"];
  const tableBody = ["storeName", "buildDate"];
  var userStoreName = getUserStoreNameFromLocalStorage();
  const headerObject = {
    title: `SOUL ORIGIN (${userStoreName})`,
    subTitle: "Stores With No Actuals",
  };
  return (
    <React.Fragment>
      <div className="row ml-lg-3 mt-md-3 text-left">
        <Header {...headerObject} />
        <div>
          <div className="row m-lg-3 justify-content-left w-100 mt-5 mx-auto">
            <DatePicker
              dpLabel="From Date"
              selectedDate={fromSelectedDate}
              handleDateChange={handleFromDateChange}
              minAllowedDate={minFromSelectedDate}
              maxAllowedDate={maxFromSelectedDate}
            />
            <DatePicker
              dpLabel="To Date"
              selectedDate={toSelectedDate}
              handleDateChange={handleToDateChange}
              minAllowedDate={minToSelectedDate}
              maxAllowedDate={maxToSelectedDate}
            />
            <React.Fragment>
              <div className="col-md-3 mb-md-4 mt-md-3">
                <Button
                  onClick={generateReport}
                  variant="contained"
                  color="primary"
                  className="btn btn-primary ctm-btn"
                >
                  Generate Report
                </Button>
              </div>
            </React.Fragment>
          </div>
          {databackUp.length === 0 && (
            <div className="no-rcd">
              <p>{msg}</p>{" "}
            </div>
          )}
          {databackUp.length > 0 && (
            <div>
              <SearchBar
                value={searchValue}
                className="w-100 ml-auto border br-8 shade-0 my-5"
                onChange={onSearchData}
                onCancelSearch={onClearSearch}
              />

              {reportData.length > 0 && (
                <ReportTable
                  tbName="Build_By_Date_Range"
                  tbHeaders={headers}
                  tbData={reportData}
                  tbBody={tableBody}
                  csvData={csvData}
                  dtFormat="DD/MM/YYYY"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
