import * as React from "react";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { format, addDays } from "date-fns";
import SearchBar from "material-ui-search-bar";

type BaseProps = {
  selectedCategory?: string;
  productionDate: Date;
  setProductionDate: (newDate: Date) => void;
  leftAdditionalTools?: React.ReactNode
  rightAdditionalTools?: React.ReactNode
}

type ForbidProps<T> = {
  [P in keyof T]?: never;
}

type OptionalSearchProps = {
  hasSearch: true
  searchText: string;
  onSearchTextChange: (val: string) => void;
}

type OptionalShowAllProps = {
  hasShowAll: true
  showAll: boolean;
  toggleShowAll: (val: boolean) => void
}

type WithSearch = BaseProps & (OptionalSearchProps | ForbidProps<OptionalSearchProps>)
type WithShowAll = BaseProps & (OptionalShowAllProps | ForbidProps<OptionalShowAllProps>)
type ToolbarProps = WithSearch & WithShowAll

export default function ToolBar({
  selectedCategory,
  searchText,
  onSearchTextChange,
  productionDate,
  setProductionDate,
  showAll,
  toggleShowAll,
  hasSearch,
  hasShowAll,
  rightAdditionalTools,
  leftAdditionalTools
}: ToolbarProps) {
  const changeDate = (change: number) => setProductionDate(addDays(productionDate, change));

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between p-2 col-12 border-bottom">
        <div>
          {selectedCategory && <h4 className="my-auto font-weight-bold">{selectedCategory}</h4>}
          {leftAdditionalTools ? leftAdditionalTools : null}
        </div>

        <div className="mx-2 d-flex justify-content-end">
          {hasSearch && <SearchBar
            value={searchText}
            className="w-40 ml-auto border br-8 shade-0"
            onChange={onSearchTextChange}
            onCancelSearch={() => onSearchTextChange("")}
          />}
          {rightAdditionalTools ? rightAdditionalTools : null}
          <button onClick={() => changeDate(-1)} className="bg-white border-0 ml-2">
            <i className="fa-2x fa fa-angle-left"></i>
          </button>
          <p className="my-auto mx-2">{format(productionDate, "dd/MM/yyyy")}</p>
          <button onClick={() => changeDate(1)} className="bg-white border-0 mr-2">
            <i className="fa-2x fa fa-angle-right"></i>
          </button>
          {hasShowAll && <BootstrapSwitchButton
            width={150}
            offstyle="dark"
            checked={showAll}
            onlabel="Hide"
            offlabel="Show All"
            onChange={toggleShowAll}
          />}
        </div>
      </div>
    </React.Fragment>
  );
}
