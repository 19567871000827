import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { getHeaders } from "../../../helpers/helper";
import axiosInstance from "../../../shared/config/axiosInstance";
import { ProductionWeeklyPlan } from "../../../shared/models/ProductionPlanning";
import { MutationKeys } from "../../../shared/react-query/mutationKeys";

async function updateProductionPlan(
  reqBody: ProductionWeeklyPlan,
  getAccessTokenSilently: any
) {
  const headers = await getHeaders(getAccessTokenSilently);

  const {data} = await axiosInstance.post("productionplanning", reqBody, {
    headers: { ...headers, "Content-Type": "application/json" },
  });

  return data;
}

export function useUpdateProductionPlan() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(
    (data: ProductionWeeklyPlan) =>
      updateProductionPlan(data, getAccessTokenSilently),
    {
      mutationKey: [MutationKeys.PRODUCTION_PLANNING],
    }
  );
}
