import { useEffect, useState } from 'react'
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/monokai';
import Stack from 'rsuite/Stack'
import Button from 'rsuite/Button'
import Message from 'rsuite/Message'
// @ts-ignore
import { JsonEditor } from 'jsoneditor-react';
import Loader from 'rsuite/Loader'
import './PrepListSettings.css'
import { useToaster } from 'rsuite/esm/toaster'
import { useProductPrepItems } from './hooks/useProductPrepItems';
import { useUpdateProductPrepItems } from './hooks/useUpdateProductPrepItems';

const ProductPrepItems = () => {
  const [json, setJson] = useState<any>();
  const { data } = useProductPrepItems();
  const { mutate: updateProductPrepItems, isLoading: savingInProgress } = useUpdateProductPrepItems();
  const toaster = useToaster();

  useEffect(() => {
    if (!data) {
      return;
    }

    setJson(data)
  }, [data])

  const handleEditorChange = (value: any) => {
    setJson(value || {})
  }

  const handleSave = () => {
    updateProductPrepItems(json, {
      onSuccess: () => {
        const message = "Product prep items saved successfully";
        toaster.push(
          <Message showIcon type='success' closable>
            {message}
          </Message>,
          { placement: 'topEnd', duration: 3000 }
        )
      },
      onError: () => {
        const message = "Error while saving product prep items. Please try again.";
        toaster.push(
          <Message showIcon type='error' closable>
            {message}
          </Message>,
          { placement: 'topEnd', duration: 3000 }
        )
      }
    })
  }

  return (
    <div>
      {json
        ? <JsonEditor
          value={json}
          onChange={handleEditorChange}
          mode='code'
          ace={ace}
          theme="ace/theme/monokai"
          allowedModes={['code', 'tree']}
        />
        : <Loader center />
      }
      <Stack style={{marginTop: '8px', marginBottom: '8px'}} direction='row-reverse'>
        <Button onClick={handleSave} appearance='primary'>
          {savingInProgress && <Loader style={{marginRight: '8px'}} />}
          Save
        </Button>
      </Stack>
    </div>

  )
}

export default ProductPrepItems