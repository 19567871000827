import { Product } from "../../../shared/models/Product";
import Notes from "../../common/Notes";
import ActionButtonNew from "../../common/ActionButtonNew";
import { useUpdateProductNotes } from "../hooks/useUpdateProductNotes";
import FileGuide from "./FileGuide";

export interface ChangedProduct extends Product {
  isDirty?: boolean;
}

interface Props {
  product: Product;
  onChange?: (product: Product) => void;
  isDisabled?: boolean;
}

const ProductRow = (props: Props) => {
  const { product, onChange, isDisabled } = props;

  const { mutate: updateProductNotes, isLoading: savingNotes } = useUpdateProductNotes();

  const onProductChange = (newProduct: Product) => {
    if (onChange) {
      onChange({
        ...newProduct,
        isDirty: true,
      });
    }
  };

  const onQuantityChange = (change: number) => {
    const newProduct: ChangedProduct = { ...product };
    newProduct.producedQuantity = Math.max(
      0,
      newProduct.producedQuantity + change
    );
    onProductChange(newProduct);
  };

  const onSaveNotes = (changedProduct: Product, closeDialogCb: () => void) => {
    updateProductNotes(changedProduct, {
      onSuccess: (data: any) => {
        if (onChange) {
          onChange({ ...changedProduct, id: data });
        }

        closeDialogCb();
      },
      onError: () => {
        closeDialogCb();
      }
    });
  };

  return (
    <div className="mx-1 row shade-row my-0 py-2 rounded mt-3 table-font">
      <div className="col-2 my-auto">
        <p className="mb-0">{product.productName}</p>
      </div>
      <div className="col-2 col-lg-1 my-auto text-center">
        <span className="text-center">
          <span className="mb-0">{product.plannedQuantity}</span>
        </span>
      </div>

      <div className="col-2 my-auto text-center">
        <p
          className={`mb-0 ${
            product.producedQuantity > 0 ? "text-danger" : ""
          }`}
        >
          {product.plannedQuantity - product.producedQuantity}
        </p>
      </div>

      <div className="my-auto col-4 col-lg-5 text-center">
        <div className=" row">
          <ActionButtonNew
            label={"-5"}
            isDisabled={!!isDisabled}
            colorClass={"ctm-btn col-lg-1 bg-sec text-white d-none d-lg-block"}
            onClick={() => onQuantityChange(-5)}
          />
          <ActionButtonNew
            label={"-1"}
            isDisabled={!!isDisabled}
            colorClass={"ctm-btn col-lg-1 bg-sec text-white"}
            onClick={() => onQuantityChange(-1)}
          />
          <span className={`col-lg-2 ctm-btn h-fit py-1 mr-2 mr-md-1 px-2`}>
            {product.producedQuantity}
          </span>
          <ActionButtonNew
            label={"+1"}
            isDisabled={!!isDisabled}
            colorClass={"ctm-btn col-lg-1 bg-org text-white"}
            onClick={() => onQuantityChange(1)}
          />
          <ActionButtonNew
            label={"+5"}
            isDisabled={!!isDisabled}
            colorClass={"ctm-btn col-lg-1 bg-org text-white d-none d-lg-block"}
            onClick={() => onQuantityChange(5)}
          />
          <ActionButtonNew
            label={`[${Math.max(
              0,
              product.plannedQuantity - product.producedQuantity
            )}]`}
            isDisabled={!!isDisabled}
            colorClass={"ctm-btn col-lg-1 bg-org text-white d-none d-lg-block"}
            onClick={() => onQuantityChange(Math.max(0, product.plannedQuantity - product.producedQuantity))}
          />
        </div>
      </div>
      <div className="col-2 mx-auto my-auto text-center">
        <FileGuide PLU={product.plu} iconSize={38} guideUrl="/fileguide" />

        <Notes product={product} handleSubmit={onSaveNotes} savingNotesInProgess={savingNotes} />
      </div>
    </div>
  );
};

export default ProductRow;
