import { useState } from "react";
import { format, isToday, isTomorrow } from "date-fns";
import "./Catering.css";
import { CateringOrder, OrderItem } from "../../shared/models/CateringOrder";
import { MdOutlineDeliveryDining } from "react-icons/md";
import { AiOutlineShop } from "react-icons/ai";
import FileGuide from "../Production/components/FileGuide";

interface Props {
  order: CateringOrder;
}

export const OrderCard = ({ order }: Props) => {
  const [showDeliveryDetails, setShowDeliveryDetails] = useState(false);

  const toggleShowDeliveryDetails = () => {
    setShowDeliveryDetails((prev) => !prev);
  };

  const isLocalDelivery = () => {
    return (
      order.checkoutMethod.toLowerCase() === "delivery" &&
      order.tags.toLowerCase().includes("local delivery")
    );
  };

  const getCheckoutIcon = () => {
    if (!order.checkoutMethod) {
      return null;
    }

    let icon = <AiOutlineShop size="3.5em" color="#FFB81C" />;

    if (isLocalDelivery()) {
      icon = (
        <>
          <MdOutlineDeliveryDining
            size="3.5em"
            color="#FFB81C"
            display={"block"}
          />
          {order.trackingUrl && order.trackingCompany && (
            <a
              href={order.trackingUrl}
              target="_blank"
              rel="noreferrer noopener"
              onClick={() =>
                window.open(order.trackingUrl, "Track", "width=600,height=400")
              }
            >
              {order.trackingCompany}
            </a>
          )}
        </>
      );
    }

    return (
      <div
        style={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          zIndex: 10,
        }}
      >
        <div style={{ fontWeight: "bold" }}>
          {order.checkoutMethod.toUpperCase()}
        </div>
        {icon}
      </div>
    );
  };

  return (
    <div
      className="row order-card"
      style={{
        backgroundColor: getOrderBackgroundColor(order.fulfillmentDate),
      }}
    >
      <div className="col-6" style={{ position: "relative" }}>
        {getCheckoutIcon()}
        <div className="row">
          <div className="col-6 text-right font-weight-bold">Order No.</div>
          <div className="col-6">
            <span>{order.id} </span>
          </div>
        </div>
        <div className="row">
          <div className="col-6 text-right font-weight-bold">
            Fulfillment Date
          </div>
          <div className="col-6">
            <span
              className="fulfillment-time"
              style={{
                background: "gray",
                color: "#fff",
              }}
            >
              {getFormattedDate(order.fulfillmentDate, "dd/MM/yyyy")}{" "}
              {order.fulfillmentTime}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-6 text-right font-weight-bold">Order Total</div>
          <div className="col-6">${order.currentTotalPrice}</div>
        </div>
        <div className="row">
          <div className="col-6 text-right font-weight-bold">Customer Name</div>
          <div className="col-6">{order.customerName}</div>
        </div>
        <div className="row">
          <div className="col-6 text-right font-weight-bold">
            Customer Email
          </div>
          <div className="col-6">{order.customerEmail}</div>
        </div>
        <div className="row">
          <div className="col-6 text-right font-weight-bold">Phone</div>
          <div className="col-6">{order.customerPhone}</div>
        </div>
        <div className="row">
          <div className="col-6 text-right font-weight-bold">Occasion</div>
          <div className="col-6">{order.occasion}</div>
        </div>
        {order.customerNote && (
          <div className="row">
            <div className="col-6 text-right font-weight-bold">
              Customer Notes
            </div>
            <div className="col-6">
              <span className="customer-notes">{order.customerNote}</span>
            </div>
          </div>
        )}
        {isLocalDelivery() && (
          <div className="row">
            <div className="col-6 text-right font-weight-bold">
              Delivery Details
            </div>
            <div className="col-6">
              <span
                onClick={toggleShowDeliveryDetails}
                style={{ cursor: "pointer", color: "gray" }}
              >
                {showDeliveryDetails ? "(Hide)" : "(Show)"}
              </span>
              {showDeliveryDetails && (
                <div>
                  {order.deliveryTime && (
                    <div>
                      <span className="font-weight-bold">Delivery Time: </span>
                      {order.deliveryTime}
                    </div>
                  )}
                  {order.shippingAddress.name && (
                    <div className="font-weight-bold">
                      {order.shippingAddress.name}
                    </div>
                  )}
                  {order.trackingCompany && (
                    <div>
                      <span className="font-weight-bold">
                        {order.shippingAddress.company}
                      </span>
                    </div>
                  )}
                  {order.shippingAddress.address1 && (
                    <span>{order.shippingAddress.address1}, </span>
                  )}
                  {order.shippingAddress.address2 && (
                    <span>{order.shippingAddress.address2}, </span>
                  )}
                  {order.shippingAddress.city && (
                    <span>
                      {order.shippingAddress.city} {order.shippingAddress.zip}
                    </span>
                  )}
                  {order.shippingAddress.phone && (
                    <div>{order.shippingAddress.phone}</div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="col-6 d-flex flex-column border-left">
        <div className="row pb-2">
          <div className="col-1">
            <span className="fw-bold">Qty</span>
          </div>
          <div className="col-11">
            <span className="fw-bold">Item</span>
          </div>
        </div>
        {order.lineItems
          .sort((a, b) => a.productName.localeCompare(b.productName))
          .map((item, index) => (
            <LineItem key={`item-${index}`} item={item} />
          ))}
      </div>
    </div>
  );
};

const LineItem = ({ item }: { item: OrderItem }) => {
  const isSaladPot = (productName: string) => {
    return productName.toLocaleLowerCase().startsWith("salad pots");
  };

  const getProductName = () => {
    const { productName } = item;
    if (isSaladPot(productName)) {
      return "Salad Pots";
    }

    return productName;
  };

  if (+item.fulfillableQuantity === 0) {
    return null;
  }

  return (
    <div className="row pb-1">
      <div className="col-1">
        <span className="item__item-quantity">{item.fulfillableQuantity}</span>
      </div>
      <div className="col-11">
        <div className="item__item-name">
          <span>{getProductName()}</span>
          <FileGuide PLU={item.productCode} iconSize={25} guideUrl="/fileguide" />
        </div>
        {item.firstVariant && (
          <div className="item__item-notes">
            <span className="item__notes-label">First Variant: </span>
            <span className="item__notes-content">{item.firstVariant}</span>
          </div>
        )}
        {item.secondVariant && (
          <div className="item__item-notes">
            <span className="item__notes-label">Second Variant: </span>
            <span className="item__notes-content">{item.secondVariant}</span>
          </div>
        )}
        {item.firstDressing && (
          <div className="item__item-notes">
            <span className="item__notes-label">First Dressing: </span>
            <span className="item__notes-content">{item.firstDressing}</span>
          </div>
        )}
        {item.secondDressing && (
          <div className="item__item-notes">
            <span className="item__notes-label">Second Dressing: </span>
            <span className="item__notes-content">{item.secondDressing}</span>
          </div>
        )}
        {item.itemNote && <div className="customer-notes" style={{marginLeft: '8px'}}>{item.itemNote}</div>}
      </div>
    </div>
  );
};

const getFormattedDate = (dateTimeString: string, displayFormat: string) => {
  if (isNaN(Date.parse(dateTimeString))) {
    return "Invalid DateTime string";
  }

  const date = new Date(dateTimeString);
  return format(date, displayFormat);
};

const getOrderBackgroundColor = (fulfillmentDate: string) => {
  const fDate = new Date(fulfillmentDate);
  const d = fDate.getDate();

  if (isToday(fDate)) {
    return "#ffcb77";
  } else if (isTomorrow(fDate)) {
    return "#c9ffe0";
  } else if (d % 2 === 0) {
    return "#fff";
  } else {
    return "#f2f2f2";
  }
};
