import React from "react";
import ReactDOM from "react-dom";
import App, { history } from "./App";
import { Auth0Provider, AppState } from "@auth0/auth0-react";
import registerServiceWorker from "./registerServiceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "toastr/build/toastr.min.css";
import "./index.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "./shared/react-query/queryClient";
import { ThemeProvider } from "@material-ui/core";
import { muiTheme } from "./shared/config/MuiTheme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const onRedirectCallback = (appState: AppState | undefined) => {
  // If using a Hash Router, you need to use window.history.replaceState to
  // remove the `code` and `state` query parameters from the callback url.
  // window.history.replaceState({}, document.title, window.location.pathname);
  history.replace((appState && appState.returnTo) || window.location.pathname);
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Auth0Provider
          domain={String(process.env.REACT_APP_DOMAIN)}
          clientId={String(process.env.REACT_APP_CLIENT_ID)}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: process.env.REACT_APP_AUDIENCE,
            scope: 'makeapp'
          }}
          cacheLocation="localstorage"
          onRedirectCallback={onRedirectCallback}
        >
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Auth0Provider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

registerServiceWorker();
