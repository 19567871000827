import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { getHeaders } from "../../../helpers/helper";
import axiosInstance from "../../../shared/config/axiosInstance";
import { MutationKeys } from "../../../shared/react-query/mutationKeys";
import { PrepArticle } from "../../../shared/models/PrepList";

async function updatePrepList(
  reqBody: PrepArticle,
  getAccessTokenSilently: any
) {
  const headers = await getHeaders(getAccessTokenSilently);

  const {data} = await axiosInstance.post("prep", [reqBody], {
    headers: { ...headers, "Content-Type": "application/json" },
  });

  return data;
}

export function useUpdatePrepList() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(
    (data: PrepArticle) =>
      updatePrepList(data, getAccessTokenSilently),
    {
      mutationKey: [MutationKeys.PREP],
    }
  );
}
