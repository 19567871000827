import { useQuery } from "@tanstack/react-query";

import { QueryKeys } from "../../../shared/react-query/queryKeys";
import axiosInstance from "../../../shared/config/axiosInstance";
import { getHeaders } from "../../../helpers/helper";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { ITakeHomeSpecial } from "../../../shared/models/TakeHomeSpecial";
import { format } from "date-fns";

async function fetchTakeHomeSpecials(
  reqDate: Date,
  getAccessTokenSilently: any
): Promise<ITakeHomeSpecial[]> {
  // axios request to fetch
  const headers = await getHeaders(getAccessTokenSilently);
  const fetchDate = format(reqDate, "yyyy-MM-dd");

  const { data } = await axiosInstance.get("/takehomespecial", {
    params: {
      productionDate: fetchDate,
    },
    headers: headers,
  });
  return data;
}

export function useTakeHomeSpecials() {
  const [date, setDate] = useState(new Date());
  const { getAccessTokenSilently } = useAuth0();

  const { data = [] } = useQuery([QueryKeys.TAKE_HOME, format(date, "yyyy-MM-dd")], () =>
    fetchTakeHomeSpecials(date, getAccessTokenSilently)
  );

  return { data, date, setDate };
}
