import { ComponentType, useEffect } from "react"
import { isAdmin as _isAdmin } from "../../../helpers/helper"
import { useHistory } from 'react-router-dom'
import { useToaster } from 'rsuite/esm/toaster'
import Message from 'rsuite/Message'

const withAdminOnlyAccess = <P extends object>(Component: ComponentType<P>) => {
  return function WithAdminOnlyAccess(props: P) {
    const isAdmin = _isAdmin()
    const history = useHistory(); 
    const toaster = useToaster();

    useEffect(() => {
      if (isAdmin) return;

      const message = "Page access allowed only for admin role";
      toaster.push(
        <Message showIcon type='error' closable>
          {message}
        </Message>,
        { placement: 'topEnd', duration: 3000 }
      )
      history.replace('/')
    }, [isAdmin, history, toaster])

    return isAdmin ? <Component {...props} /> : <></>
  }
}

export default withAdminOnlyAccess;