import { getWeek, getYear, isBefore, startOfISOWeek } from "date-fns";
import { useState } from "react";
import { ProductWeeklyPlan } from "../../shared/models/ProductionPlanning";
import { useUpdateProductionPlan } from "./hooks/useUpdateProductionPlan";
import ProductPlanRow from "./ProductPlanRow";

interface Props {
  productPlans: ProductWeeklyPlan[];
  planningWeekStartDate: Date;
  onProductPlanChange: (updatedPlan: ProductWeeklyPlan) => void;
  selectedProduct?: ProductWeeklyPlan;
  onRowSelected: (newProduct: ProductWeeklyPlan) => void;
  onProductChange: (updatedPlan: ProductWeeklyPlan) => void;
  onSaveProductPlan: () => void;
}

const ProductPlanRows = ({
  productPlans,
  planningWeekStartDate,
  onProductPlanChange,
  selectedProduct,
  onRowSelected,
  onProductChange,
  onSaveProductPlan
}: Props) => {

  return (
    <>
      <div className="mx-1 text-center row bg-header my-0 py-3 rounded mt-3">
        <div className="col-lg-3 col text-left">
          <h5 className="md-h5 mb-0">ITEMS</h5>
        </div>
        <div className="col">
          <h5 className="mb-0 md-h5">MON</h5>
        </div>
        <div className="col">
          <h5 className="mb-0 md-h5">TUE</h5>
        </div>
        <div className="col">
          <h5 className="mb-0 md-h5">WED</h5>
        </div>
        <div className="col">
          <h5 className="mb-0 md-h5">THU</h5>
        </div>
        <div className="col">
          <h5 className="mb-0 md-h5">FRI</h5>
        </div>
        <div className="col">
          <h5 className="mb-0 md-h5">SAT</h5>
        </div>
        <div className="col">
          <h5 className="mb-0 md-h5">SUN</h5>
        </div>
        <div className="col" />
      </div>
      {productPlans.map((product, index) => (
        <ProductPlanRow
          key={`product#${index}`}
          product={product}
          selected={selectedProduct}
          onSelectProduct={onRowSelected}
          onChange={onProductChange}
          onSave={onSaveProductPlan}
          disabled={isBefore(planningWeekStartDate, startOfISOWeek(new Date()))}
        />
      ))}
    </>
  );
};

export default ProductPlanRows;
