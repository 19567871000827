import React, { useState } from "react";
import { Product } from "../../../shared/models/Product";
import ToolBar from "../../common/ToolBar";
import DataRows from "./DataRows";

interface Props {
  productionData: Product[];
  selectedCategory: string;
  prodDate: Date;
  setProdDate: (newDate: Date) => void;
  onProductChange: (newProduct: Product) => void;
}

const ProductionGrid = ({
  prodDate,
  selectedCategory,
  productionData,
  setProdDate,
  onProductChange,
}: Props) => {
  const [searchText, setSearchText] = useState("");
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll((prevState) => !prevState);
  };

  const getFilteredData = () => {
    let filtered = [...productionData];

    if (!showAll) {
      filtered = filtered.filter((product) => product.plannedQuantity !== 0);
    }

    if (searchText) {
      filtered = filtered.filter((product) =>
        product.productName.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (selectedCategory) {
      filtered = filtered.filter(
        (product) => product.productCategory === selectedCategory
      );
    }

    return filtered;
  };
  return (
    <div className="w-100 mt-5 mx-auto">
      <div className="m-lg-3 mx-0 row t-shade">
        <div className="bg-white">
          <ToolBar
            hasSearch
            searchText={searchText}
            onSearchTextChange={(val: string) => setSearchText(val)}
            productionDate={prodDate}
            setProductionDate={setProdDate}
            selectedCategory={selectedCategory}
            hasShowAll
            showAll={showAll}
            toggleShowAll={toggleShowAll}
          />

          <DataRows
            data={getFilteredData()}
            onChange={onProductChange}
            productionDate={prodDate}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductionGrid;
