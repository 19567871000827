import React from "react";
import reportIcon from "../../assets/images/Assets/reports.png";
import HomeButton from "../common/HomeButton";
import Header from "../common/Header";
import { getUserStoreNameFromLocalStorage } from "../../helpers/localStorageHelper";

export function Reports() {
  var userStoreName = getUserStoreNameFromLocalStorage();

  const headerObject = {
    title: `SOUL ORIGIN (${userStoreName})`,
    subTitle: "Reports",
  };
  return (
    <>
      <div className="row justify-content-space-around pr-lg-4 ml-lg-5">
        <Header {...headerObject} />
        <div className="row justify-content-space-around pr-lg-4 my-lg-5">
          <HomeButton
            hbIcon={reportIcon}
            hbName={"Build By Date Range"}
            hbUrl={"/detailreport1"}
          />
          <HomeButton
            hbIcon={reportIcon}
            hbName={"Daily Production Timeline"}
            hbUrl={"/detailreport2"}
          />
          <HomeButton
            hbIcon={reportIcon}
            hbName={"High Variance Report"}
            hbUrl={"/detailreport3"}
          />
        </div>
      </div>
    </>
  );
}
