import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import note_img from "../../assets/images/Assets/sticky.svg";
import note_added from "../../assets/images/Assets/note_added.png";
import React, { useEffect } from "react";
import { Product } from "../../shared/models/Product";
import { ITakeHomeSpecial } from "../../shared/models/TakeHomeSpecial";
import { isToday } from "date-fns";

interface Props {
  product: Product | ITakeHomeSpecial;
  handleSubmit: (changedProduct: any, cb: () => void) => void;
  savingNotesInProgess: boolean;
}

export default function Notes(props: Props) {
  const { product, handleSubmit, savingNotesInProgess } = props;
  const today = moment().format("YYYY-MM-DD");
  const isPreviousDay = !(
    moment(product.productionDate).format("YYYY-MM-DD") === today
  );
  const [open, setOpen] = React.useState(false);
  const [note, setNote] = React.useState(product.notes);
  // useEffect(()=>{setNote(notes)},[])

  useEffect(() => {
    setNote(product.notes)
  }, [product.notes])

  const handleClickOpen = () => {
    // setNote(notes);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    const updatedObj = {
      ...props.product,
      notes: note + "\n",
    };

    handleSubmit(updatedObj, () => setOpen(false));
  };

  const isDisabled = () => {
    return !isToday(new Date(product.productionDate)) && !product.notes;
  };

  return (
    <div style={{ display: "inline" }}>
      <Button onClick={handleClickOpen} disabled={isDisabled()}>
        {/* <p className = "highlight"><i className="fa fa-3x fa-sticky-note v-align" /></p> */}
        {/* <img className={notes==null?"note-added":"note"} src={note_img} alt="note png" /> */}
        <img
          className={product.notes != null ? "note-added" : "note"}
          id={`${product.productId}_note`}
          src={
            product.notes !== null && product.notes !== ""
              ? note_added
              : note_img
          }
          alt="note png"
          style={{ opacity: isDisabled() ? "0.5" : "1" }}
        />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div style={{ width: 600 }}>
          <DialogTitle id="form-dialog-title">Notes</DialogTitle>
          <DialogContent>
            {isPreviousDay && (
              <DialogContentText>
                {product.notes ?? "Add Precise Note."}
              </DialogContentText>
            )}
            {!isPreviousDay && (
              <TextField
                value={note}
                autoFocus
                margin="dense"
                id={`${product.productId}_note2`}
                label="Note"
                type="textarea"
                multiline
                rowsMax="10"
                fullWidth
                onFocus={(e) =>
                  e.currentTarget.setSelectionRange(
                    e.currentTarget.value.length,
                    e.currentTarget.value.length
                  )
                }
                onChange={(event) => setNote(event.target.value)}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            {!isPreviousDay && (
              <Button onClick={handleAdd} color="primary">
                {savingNotesInProgess && (
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Add
              </Button>
            )}
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
