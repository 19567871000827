export enum QueryKeys {
    PRODUCTION = 'PRODUCTION',
    TAKE_HOME = 'TAKE_HOME',
    PRODUCTION_PLANNING = 'PRODUCTION_PLANNING',
    CATERING_ORDERS = 'CATERING_ORDERS',
    CATERING_ORDER_COUNT = 'CATERING_ORDER_COUNT',
    FILE_GUIDE = 'FILE_GUIDE',
    PREP_LIST = 'PREP_LIST',
    PREP_LIST_SETTINGS = 'PREP_LIST_SETTINGS',
    PRODUCT_PREP_ITEMS= 'PRODUCT_PREP_ITEMS'
}
