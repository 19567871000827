import "../App.css";
import planIcon from "../assets/images/Assets/Production.png";
import stockIcon from "../assets/images/Assets/Produce.png";
import specialsIcon from "../assets/images/Assets/afternoon.png";
import reportIcon from "../assets/images/Assets/reports.png";
import { getDeviceType } from "../helpers/helper";
import HomeButton from "./common/HomeButton";
import Header from "./common/Header";
import {
  getUserStoreIdFromLocalStorage,
  getUserStoreNameFromLocalStorage,
} from "../helpers/localStorageHelper";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2"

const device = getDeviceType();

export function Home() {
  const userStoreId = getUserStoreIdFromLocalStorage();
  const userStoreName = getUserStoreNameFromLocalStorage();
  const headerObject = {
    title: `SOUL ORIGIN (${userStoreName})`,
    subTitle: "",
  };

  const mystyle = {
    marginLeft: "35px",
  };

  return (
    <>
      <div className="h-100 banner3 container d-flex">
        <div
          className="row justify-content-space-around pr-lg-4 ml-lg-5"
          style={mystyle}
        >
          <Header {...headerObject} />
          <p className="pl-lg-5 py-2">
            {/* You shouldn’t have to compromise nutrition for convenience. We offer wholesome options for all times of the day.! Use Make App for Production planning, produce stock or record wastage and your reporting ! */}
          </p>
          <HomeButton
            hbIcon={planIcon}
            hbName={"Build To"}
            hbUrl={"/planning"}
          />
          <HomeButton
            hbIcon={stockIcon}
            hbName={"Daily Production"}
            hbUrl={"/production"}
          />
          <HomeButton
            hbIcon={specialsIcon}
            hbName={"Take Home Specials"}
            hbUrl={"/takehomespecial"}
            size={device === "desktop" ? "default" : "adjusted"}
          />
          <HomeButton
            icon={<HiOutlineClipboardDocumentList size={60} style={{strokeWidth: '1px', marginTop: '10px'}}/>}
            hbName={"Prep"}
            hbUrl={"/prep"}
          />
          <HomeButton
            hbIcon={reportIcon}
            hbName={"Reports"}
            hbUrl={"/reports"}
          />
          <HomeButton
            hbIcon={reportIcon}
            hbName={"Head Office Reports"}
            hbUrl={userStoreId === "9999" ? "/HeadOffice" : ""}
          />
        </div>
      </div>
    </>
  );
}
