import { useAuth0 } from "@auth0/auth0-react";
import { getHeaders } from "../../../helpers/helper";
import axiosInstance from "../../../shared/config/axiosInstance";
import { IWeekInfo } from "../../../shared/models/IWeekInfo";
import { useMutation } from "@tanstack/react-query";
import { MutationKeys } from "../../../shared/react-query/mutationKeys";


async function copyPrevWeek(reqBody: IWeekInfo, getAccessTokenSilently: any) {
  const headers = await getHeaders(getAccessTokenSilently)

  const { data } = await axiosInstance.post("productionplanning/CopyFromAsync", reqBody, {
    headers: { ...headers, "Content-Type": "application/json" }
  })

  return data
}


export function useCopyDataFromPrevWeek() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation((data: IWeekInfo) => copyPrevWeek(data, getAccessTokenSilently), {
    mutationKey: [MutationKeys.PRODUCTION_PLANNING_COPY_FROM]
  })
}