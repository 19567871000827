import { differenceInSeconds } from "date-fns";
import _ from "lodash";
import { CateringOrder } from "../../../shared/models/CateringOrder";

export enum SortKeys {
  UPDATED_AT = "updatedDateTime",
  FULFILLMENT_DATE = "fulfillmentDate",
}

const getFulfillmentDateTime = (order: CateringOrder) => {
  const fDate = new Date(order.fulfillmentDate);
  const fTime = order.fulfillmentTime.match(/^(\d+):(\d+)\s(AM|PM)/);

  if (!fTime) {
    return fDate;
  }

  const fDateTime = new Date(fDate.toDateString() + " " + fTime[0]);
  return fDateTime;
};

export const sortArray = (arr: any[], sortBy: SortKeys, ascending: boolean) => {
  const sorted = _.cloneDeep(arr).sort((a, b) => {
    const d1 =
      sortBy === SortKeys.UPDATED_AT
        ? new Date(a[sortBy])
        : getFulfillmentDateTime(a);
    const d2 =
      sortBy === SortKeys.UPDATED_AT
        ? new Date(b[sortBy])
        : getFulfillmentDateTime(b);

    const diff = differenceInSeconds(d1, d2);
    return ascending ? diff : -diff;
  });

  return sorted;
};
