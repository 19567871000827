import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { getHeaders } from "../../../helpers/helper";
import axiosInstance from "../../../shared/config/axiosInstance";
import { QueryKeys } from "../../../shared/react-query/queryKeys";
import { ProductPrepItemDto } from "../../../shared/models/ProductPrepItems";

async function fetchProductPrepItems(getAccessTokenSilently: any): Promise<ProductPrepItemDto> {
  const headers = await getHeaders(getAccessTokenSilently);
  
  const { data } = await axiosInstance.get('/admin/GetProductPrepItems', {
    headers
  })

  return data;
}

export function useProductPrepItems() {
   const { getAccessTokenSilently } = useAuth0();

   return useQuery([QueryKeys.PRODUCT_PREP_ITEMS], () => fetchProductPrepItems(getAccessTokenSilently))
}