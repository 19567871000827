import { useEffect, useState } from "react";
import { getUserStoreNameFromLocalStorage } from "../../helpers/localStorageHelper";
import { ProductWeeklyPlan } from "../../shared/models/ProductionPlanning";
import CategoryFilterButtons from "../common/CategoryFilterButtons";
import Header from "../common/Header";
import { useProductionPlanning } from "./hooks/useProductionPlanning";
import PlanningGrid from "./PlanningGrid";

const NewPlanning = () => {
  const { productionPlanningData, weekStartDate, changeWeek, isLoadingPlanningData } =
    useProductionPlanning();
  const [planningModels, setPlanningModels] = useState<ProductWeeklyPlan[]>([]);
  const [showTabs, setShowTabs] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (!productionPlanningData) {
      return;
    }

    setPlanningModels(productionPlanningData.planningModels);
  }, [productionPlanningData]);

  const openMenu = () => setIsMenuOpen(true)

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  const onSelectCategory = (categoryName: string) => {
    if (selectedCategory === categoryName) {
      setSelectedCategory("");
    } else {
      setSelectedCategory(categoryName);
    }
  };

  const onProductPlanChange = (updatedPlan: ProductWeeklyPlan) => {
    const index = planningModels.findIndex(elem => elem.productId === updatedPlan.productId);
    if (index === -1) {
      return;
    }

    const updatedPlanningModels = [...planningModels];
    updatedPlanningModels[index] = updatedPlan;
    setPlanningModels(updatedPlanningModels)
  }

  var userStoreName = getUserStoreNameFromLocalStorage();
  const headerObject = {
    title: `SOUL ORIGIN (${userStoreName})`,
    subTitle: "Build To",
  };

  return (
    <>
      <div className="row ml-lg-3 mt-md-3 text-left" onClick={() => isMenuOpen ? closeMenu() : () => {}}>
        <Header {...headerObject} />
        <CategoryFilterButtons
          showTabs={showTabs}
          onShowTabsChange={() => setShowTabs(!showTabs)}
          selectedCategory={selectedCategory}
          onCategoryChange={onSelectCategory}
        />
        <PlanningGrid
          planningModels={planningModels}
          selectedCategory={selectedCategory}
          weekStartDate={weekStartDate}
          onWeekChange={changeWeek}
          onProductPlanChange={onProductPlanChange}
          isMenuOpen={isMenuOpen}
          openMenu={openMenu}
          isLoading={isLoadingPlanningData}
        />
      </div>
    </>
  );
};

export default NewPlanning;
