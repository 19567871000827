import { ReactNode, forwardRef } from 'react';
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import Animation from 'rsuite/Animation'
import './CollapsiblePanel.css'


const Body = forwardRef<HTMLDivElement, any>((props, ref) => (
  <div
    {...props}
    ref={ref}
  >
    <div>{props.body}</div>
  </div>
))

interface Props {
  open: boolean;
  toggleOpen: () => void;
  body: ReactNode
  header?: ReactNode,
  footer?: ReactNode,
}

const CollapsiblePanel = ({
  open,
  body,
  header,
  footer,
  toggleOpen
}: Props) => {
  return (
    <div className="so-panel">
      <div className="so-panel-header">
        <div className="so-panel-header__header">{header}</div>
        <div className="so-panel-header__toggle-button">{open ? <FaChevronDown onClick={toggleOpen}/> : <FaChevronUp onClick={toggleOpen} />}</div>
      </div>
      <div>
        <Animation.Collapse in={open}>
            {(props, ref) => {
              const finalProps = {...props, body}
              return <Body {...finalProps} ref={ref} />
            }}
        </Animation.Collapse>
      </div>
      {footer && <div>
        {footer}
      </div>}
    </div>
  )

}

export default CollapsiblePanel