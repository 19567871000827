
import { useAuth0 } from "@auth0/auth0-react";
import { getHeaders } from "../../../helpers/helper";
import axiosInstance from "../../../shared/config/axiosInstance";
import { useMutation } from "@tanstack/react-query";
import { MutationKeys } from "../../../shared/react-query/mutationKeys";

async function deleteAllFutureBuildTo(getAccessTokenSilently: any) {
  const headers = await getHeaders(getAccessTokenSilently)

  const res = await axiosInstance.delete("/productionplanning/DeleteAllFutureBuildTo", {
    headers
  })

  return res.data
}

export function useDeleteAllFutureBuildTo() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: () => deleteAllFutureBuildTo(getAccessTokenSilently),
    mutationKey: [MutationKeys.DELETE_ALL_FUTURE_BUILD_TO]
  })
}
