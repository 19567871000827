import { useState } from "react";
import { addMonths, format } from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../../shared/react-query/queryKeys";
import axiosInstance from "../../../shared/config/axiosInstance";
import { getHeaders, getTodayDateWithoutTime } from "../../../helpers/helper";
// import data from './mock-data.json'

async function fetchCateringOrders(
  fromDate: Date,
  toDate: Date,
  getAccessTokenSilently: any
): Promise<any[]> {
  // axios request to fetch
  const headers = await getHeaders(getAccessTokenSilently);

  const { data } = await axiosInstance.get("/catering", {
    params: {
      orderDateFrom: format(fromDate, "yyyy-MM-dd"),
      orderDateTo: format(toDate, "yyyy-MM-dd"),
    },
    headers: {
      ...headers,
    },
  });

  return data;
}

export function useCateringOrders() {
  const today = getTodayDateWithoutTime();
  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(addMonths(today, 6));
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading } = useQuery(
    [
      QueryKeys.CATERING_ORDERS,
      format(fromDate, "yyyy-MM-dd"),
      format(toDate, "yyyy-MM-dd"),
    ],
    () => fetchCateringOrders(fromDate, toDate, getAccessTokenSilently)
  );

  return { data, fromDate, setFromDate, toDate, setToDate, isLoading };
}
