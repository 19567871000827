import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { getHeaders } from "../../helpers/helper";
import { getUserStoreIdFromLocalStorage } from "../../helpers/localStorageHelper";
import axiosInstance from "../config/axiosInstance";
import { QueryKeys } from "../react-query/queryKeys";

const fetchOrderCount = async (getAccessTokenSilently: any) => {
  const headers = await getHeaders(getAccessTokenSilently);

  const today = new Date();

  const { data } = await axiosInstance.get("/catering/count", {
    headers: headers,
    params: {
      orderDate: format(today, "yyyy-MM-dd"),
    },
  });

  return data;
};

export const useCateringOrderCount = () => {
  const { getAccessTokenSilently } = useAuth0();

  const userStoreId = getUserStoreIdFromLocalStorage();
  return useQuery([QueryKeys.CATERING_ORDER_COUNT, userStoreId], () =>
    fetchOrderCount(getAccessTokenSilently)
  );
};
