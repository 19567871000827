import { Product } from "../../../shared/models/Product";
import ProductRow from "./ProductRow";
import { isToday } from "date-fns";

interface Props {
  data: Product[];
  onChange: (product: Product) => void;
  productionDate: Date;
}

const DataRows = ({ data, onChange, productionDate }: Props) => {
  return (
    <>
      <div className="mx-1 row bg-header my-0 py-3 rounded mt-3">
        <div className="col-2 text-center">
          <h5 className="mb-0">ITEMS</h5>
        </div>
        <div className="col-2 col-lg-1 text-center">
          <h5 className="mb-0">PLAN</h5>
        </div>
        <div className="col-2 text-center">
          <h5 className="mb-0">REMAINING</h5>
        </div>
        <div className="col-4 col-lg-5 text-center">
          <h5 className="mb-0">PRODUCE</h5>
        </div>
        <div className="col-2 d-xl-block text-center">
          <h5 className="mb-0">NOTES</h5>
        </div>
      </div>
      {data.map((item) => (
        <ProductRow
          key={item.productId}
          product={item}
          onChange={onChange}
          isDisabled={!isToday(productionDate)}
        />
      ))}
    </>
  );
};

export default DataRows;
