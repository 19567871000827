import {
  endOfISOWeek,
  format,
  startOfISOWeek,
} from "date-fns";

export const getWeekStartDate = (date: Date) => {
  return format(startOfISOWeek(date), "dd/MM/yyyy");
};

export const getWeekEndDate = (date: Date) => {
  return format(endOfISOWeek(date), "dd/MM/yyyy");
};
