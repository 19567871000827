import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";

export default function DatePicker(props: any) {
  const handleDateChange = (date: any) => {
    props.handleDateChange(moment(date).format("YYYY-MM-DD"));
  };

  return (
    <React.Fragment>
      <div className="col-md-3 mb-md-4">
        <Grid container justify="space-around">
          <KeyboardDatePicker
            disableToolbar
            disableFuture={props.disableFuture}
            minDate={props.minAllowedDate}
            maxDate={props.maxAllowedDate}
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label={props.dpLabel}
            value={moment(props.selectedDate)}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            InputProps={{ readOnly: true }}
          />
        </Grid>
      </div>
    </React.Fragment>
  );
}
