import * as React from "react";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Header from '../common/Header';
import DatePicker from '../common/DatePicker';
import ReportTable from '../common/ReportTable';
import { Button } from '@material-ui/core';
import SearchBar from "material-ui-search-bar";
import {
  remove_duplicates_es6,
  AdminRedirect
} from "../../helpers/helper";
import { Loading } from "../Loading";
import { dailyProductionTimeline } from '../../handlers/reports';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";
import BarChartForReport from '../common/BarChart';
import { dummyDataDPR, bcData } from '../../helpers/mockData';
import { getUserStoreNameFromLocalStorage } from "../../helpers/localStorageHelper";

const API = process.env.REACT_APP_AUDIENCE || 3001;
export function DailyProductionReport() {

  const noData: any = [];
  const { getAccessTokenSilently } = useAuth0();
  const currentDate = new Date();
  let minAllowedDate = new Date();
  minAllowedDate.setMonth(minAllowedDate.getMonth() - 11);
  const [reportData, setReportData] = useState([]);
  const [databackUp, setDataBackup]: any = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [productData, setProductData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [forSelectedDate, setForSelectedDate] = React.useState(new Date());
  const [product, setProduct] = useState("");
  const [group, setGroup] = useState("");
  const [msg, setMsg] = useState("");
  const [csvData, setCSVData] = useState([]);
  const [state, setState] = useState<{error: any, loading: boolean}>({
    error: null,
    loading: false,
  });
  const [order, setorder] = useState("ASC");
  const handleForDateChange = (date: any) => {
    setForSelectedDate(date);
  };
  // AdminRedirect();

  const getProductList = (dat: any) => {
    let productList: any = [];
    if (dat && dat.length) {
      productList = dat.map((prod: any) => {
        return prod.product;
      })
    }
    const uniq: any = remove_duplicates_es6(productList);
    setProductData(uniq);
  };
  const getGroupList = (dat: any) => {
    let groupList: any = [];
    if (dat && dat.length) {
      groupList = dat.map((prod: any) => {
        return prod.group;
      })
    }
    const uniq: any = remove_duplicates_es6(groupList);
    setGroupData(uniq);
  };
  const getCSVData = (dat: any) => {
    let csvData: any = [];
    if (dat && dat.length) {
      csvData = dat.map((prod: any) => {
        return { ...prod, productionTime: moment(prod.productionTime).format("HH:mm") };
      });
    }
    const uniq: any = remove_duplicates_es6(csvData);
    setCSVData(uniq);
  };
  const generateReport = async () => {
    try {
      setState({ ...state, error: null, loading: true });

    
      let dat = await dailyProductionTimeline(forSelectedDate, getAccessTokenSilently);
      if (!dat || (dat && dat.length === 0)) {
        setReportData([]);
        setDataBackup([]);
        setMsg('No Records Found.');
      }
      setReportData(dat);
      setDataBackup(dat);
      getProductList(dat);
      getGroupList(dat);
      getCSVData(dat);
      setState({ ...state, error: null, loading: false });
    } catch (error) {
      setReportData([]);
      setDataBackup([]);
      setState({ ...state, error, loading: false });
    }
  };
  const onSearchData = (value: string) => {
    if (value == "") {
      setReportData(databackUp);
      return;
    }
    const lowercasedFilter = value.toLowerCase();
    const result = databackUp.filter((el: any) =>
      el.product.toLowerCase().includes(lowercasedFilter) ||
      el.group.toLowerCase().includes(lowercasedFilter)
    );
    var uniq: any = remove_duplicates_es6(result);
    setReportData(uniq);
    //todo
  }
  const onClearSearch = () => {
    setSearchValue("");
    setReportData(databackUp);
    //todo
  }
  const handleProductChange = (event: any) => {
    setProduct(event.target.value);
    if (event.target.value === "" || event.target.value === "Select") {
      setReportData(databackUp);
      return;
    }
    const filteredProduct = databackUp.filter((prod: any) => prod.product === event.target.value);
    setReportData(filteredProduct);
  }
  const handleGroupChange = (event: any) => {
    setGroup(event.target.value);
    if (event.target.value === "" || event.target.value === "Select") {
      setReportData(databackUp);
      return;
    }
    const filteredGroup = databackUp.filter((prod: any) => prod.group === event.target.value);
    setReportData(filteredGroup);
  }
  if (state.loading) {
    return <Loading />;
  }
  const headers = ['Time', 'Group', 'Product', 'Qty'];
  const tableBody = ['productionTime', 'group', 'product', 'actualQuantity'];
  var userStoreName = getUserStoreNameFromLocalStorage();
  
  const headerObject = {
    title: `SOUL ORIGIN (${userStoreName})`,
    subTitle: "Daily Production Timeline",
  };

  const sortTable = (sortedColumn:any) => {

    if(order==="ASC")
    {

      const sortedData=[...reportData].sort((a:any,b:any) => (a[sortedColumn].toLowerCase() > b[sortedColumn].toLowerCase()) ? 1 : -1)
      setReportData(sortedData);
      setorder("DESC")
    }
    if(order==="DESC")
    {

      const sortedData=[...reportData].sort((a:any,b:any) => (a[sortedColumn].toLowerCase()< b[sortedColumn].toLowerCase()) ? 1 : -1)
      setReportData(sortedData);
      setorder("ASC")
    }
  }

  return (
    <React.Fragment>
      <div className="row ml-lg-3 mt-md-3 text-left">
        <Header {...headerObject} />
        <div>
          <div className="row m-lg-3 justify-content-left w-100 mt-5 mx-auto">
            <DatePicker dpLabel="For Date" selectedDate={forSelectedDate} handleDateChange={handleForDateChange} minAllowedDate={minAllowedDate} maxAllowedDate={currentDate} />
            <React.Fragment>
              <div className="col-md-3 mb-md-4 mt-md-3">
                <Button onClick={generateReport} variant="contained" color="primary" className="btn btn-primary ctm-btn">
                  Generate Report
                </Button>
              </div>
            </React.Fragment>
          </div>
          {(databackUp.length === 0) && <div className="no-rcd"><p>{msg}</p> </div>}
          {(databackUp.length > 0) && <div>
            <SearchBar
              value={searchValue}
              className="w-100 ml-auto border br-8 shade-0 my-5"
              onChange={onSearchData}
              onCancelSearch={onClearSearch}
            />
            <FormControl variant="outlined" className="col col-lg-6">
              <InputLabel htmlFor="outlined-product">Product</InputLabel>
              <Select
                native
                value={product}
                onChange={handleProductChange}
                label="Product"
                inputProps={{
                  name: 'product',
                  id: 'outlined-product',
                }}
              >
                <option aria-label="None" value="" />
                {productData.map((item: string, index: any) => (<option key={index} value={item}>{item}</option>))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className="col col-lg-6">
              <InputLabel htmlFor="outlined-group">Group</InputLabel>
              <Select
                native
                value={group}
                onChange={handleGroupChange}
                label="Group"
                inputProps={{
                  name: 'group',
                  id: 'outlined-group',
                }}
              >
                <option aria-label="None" value="" />
                {groupData.map((item: string, index: any) => (<option key={index} value={item}>{item}</option>))}
              </Select>
            </FormControl>
            {(reportData.length > 0) && <ReportTable sortTable={sortTable} tbName='Daily_Production_Timeline' tbHeaders={headers} tbData={reportData} csvData={csvData} tbBody={tableBody} dtFormat="HH:mm" />}
            <BarChartForReport bcData={databackUp} vField="actualQuantity" augField="productionTime" title="QTY" />
          </div>}
        </div>


      </div>
    </React.Fragment>
  );
}
