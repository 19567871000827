import * as React from "react";
import Button from "./Button";

export default function ButtonGroup(props: any) {
  return (
    <React.Fragment>
      <div className="maincontainer">
        {props.buttons.map((name: string, index: any) => (
          <div key={index}>
            <Button
              name={name}
              onChangeBtnClick={props.onChangeBtnClick}
              showValue={props.showValue}
            />
          </div>
        ))}
      </div>

      <div className="row m-lg-2 justify-content-left"></div>
    </React.Fragment>
  );
}
