import { ReactNode } from "react"

interface Props {
  selectAction: (action: string) => void
  icon: ReactNode
  label: ReactNode
  action: string
}

const ActionMenuItem = ({ selectAction, icon, label, action }: Props) => {
  return (
    <li onClick={(e) => {
        selectAction(action)
        e.stopPropagation()
      }}
    >
      {icon}
      {label}
    </li>
  )
}

export default ActionMenuItem