import { OrderCard } from "./OrderCard";

interface IDetailView {
  cateringData: any[];
}

const DetailView = ({ cateringData }: IDetailView) => {
  return (
    <>
      {cateringData.map((order) => {
        return <OrderCard key={order.id} order={order} />;
      })}
    </>
  );
};

export default DetailView;
