import { useState, useEffect } from "react";
import { ProductWeeklyPlan } from "../../shared/models/ProductionPlanning";
import PlanningToolbar from "./PlanningToolbar";
import ProductPlanRows from "./ProductPlanRows";
import { useUpdateProductionPlan } from "./hooks/useUpdateProductionPlan";
import { getWeek, getYear } from "date-fns";

interface Props {
  planningModels: ProductWeeklyPlan[];
  selectedCategory: string;
  weekStartDate: Date;
  onWeekChange: (change: 1 | -1, cb?: Function) => void;
  onProductPlanChange: (updatedPlan: ProductWeeklyPlan) => void,
  isMenuOpen: boolean;
  openMenu: () => void;
  isLoading: boolean;
}



const PlanningGrid = ({
  planningModels,
  selectedCategory,
  weekStartDate,
  onWeekChange,
  onProductPlanChange,
  isMenuOpen,
  openMenu,
  isLoading
}: Props) => {
  const [searchText, setSearchText] = useState("");
  const [filteredModels, setFilteredModels] = useState<ProductWeeklyPlan[]>([])
  const [selectedProduct, setSelectedProduct] = useState<ProductWeeklyPlan>();
  const { mutate: updatePlan } = useUpdateProductionPlan();

  useEffect(() => {
    let filtered = [...planningModels];
    if (searchText) {
      filtered = filtered.filter((product) =>
        product.productName.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (selectedCategory) {
      filtered = filtered.filter(
        (product) => product.productCategory === selectedCategory
      );
    }

    setFilteredModels(filtered)
  }, [searchText, planningModels, selectedCategory])

  const onSearchTextChange = (val: string) => {
    setSearchText(val);
  };
  
  const onSaveProductPlan = () => {
    if (!selectedProduct) {
      return;
    }
    updatePlan({
      planWeek: getWeek(weekStartDate),
      planYear: getYear(weekStartDate),
      planningModels: [selectedProduct],
    });

    onProductPlanChange(selectedProduct);
    setSelectedProduct(undefined);
  };

  const onRowSelected = (newProduct: ProductWeeklyPlan) => {
    if (newProduct.productId === selectedProduct?.productId) {
      return;
    }
    onSaveProductPlan();
    setSelectedProduct(newProduct);
  };

  const onProductChange = (updatedPlan: ProductWeeklyPlan) => {
    if (updatedPlan.productId !== selectedProduct?.productId) {
      return;
    }

    setSelectedProduct(updatedPlan);
  };

  const handleWeekChange = (change: 1 | -1) => {
    onWeekChange(change, () => {
      setSelectedProduct(undefined)
    })
  }

  return (
    <div className="w-100 mt-5 mx-auto">
      <div className="m-lg-3 mx-0 row t-shade">
        <div className="bg-white">
          <PlanningToolbar
            selectedCategory={selectedCategory}
            searchText={searchText}
            onSearchTextChange={onSearchTextChange}
            weekStartDate={weekStartDate}
            onWeekChange={handleWeekChange}
            isMenuOpen={isMenuOpen}
            openMenu={openMenu}
            disabled={isLoading}
          />
          <ProductPlanRows
            productPlans={filteredModels}
            planningWeekStartDate={weekStartDate}
            onProductPlanChange={onProductPlanChange}
            selectedProduct={selectedProduct}
            onProductChange={onProductChange}
            onRowSelected={onRowSelected}
            onSaveProductPlan={onSaveProductPlan}
          />
        </div>
      </div>
    </div>
  );
};

export default PlanningGrid;
