import { useState, useMemo } from "react";
import CollapsiblePanel from "../common/uiLib/CollapsiblePanel";
import { PrepArticle } from "../../shared/models/PrepList";
import PrepListRow from "./PrepListRow";
import { isAfter, isToday } from "date-fns";

interface Props {
  items: PrepArticle[];
  currentDate: Date;
  onChange: (
    item: PrepArticle,
    successCb?: () => void,
    errorCb?: () => void
  ) => void;
  ingredient: string;
}

const PrepPanel = ({ items, currentDate, onChange, ingredient }: Props) => {
  const [open, setOpen] = useState(true);

  const totalQuantity = useMemo(() => {
    const sum = items.reduce((total, item) => {
      total += item.quantity;
      return total;
    }, 0);

    return sum;
  }, [items]);

  if (totalQuantity === 0) {
    return null;
  }

  return (
    <CollapsiblePanel
      open={open}
      toggleOpen={() => setOpen((prev) => !prev)}
      body={
        <div className="px-2 pb-3">
          {items.filter(item => item.quantity).map((item, idx) => (
            <PrepListRow
              item={item}
              currentDate={currentDate}
              isDisabled={
                !(isToday(currentDate) || isAfter(currentDate, new Date()))
              }
              onChange={onChange}
              key={`${ingredient}-prepItem-${idx}`}
            />
          ))}
        </div>
      }
      header={
        <div className="so-prep-panel-header row">
          <div className="col-4 my-auto">{ingredient}</div>
          <div className="col-2 text-center my-auto">{totalQuantity}</div>
          <div className="col-1 text-center my-auto">{items[0].uoM}</div>
          <div className="col-2 text-center my-auto"></div>
          <div className="col-1 text-center my-auto"></div>
          <div className="col-2 text-center my-auto"></div>
        </div>
      }
    />
  );
};

export default PrepPanel;
