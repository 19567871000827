import { format } from "date-fns";
import { useState } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { getHeaders } from "../../../helpers/helper";
import axiosInstance from "../../../shared/config/axiosInstance";
import { QueryKeys } from "../../../shared/react-query/queryKeys";
import { PrepArticle } from "../../../shared/models/PrepList";


async function fetchPrepList(prepDate: Date, getAccessTokenSilently: any): Promise<PrepArticle[]> {
  const headers = await getHeaders(getAccessTokenSilently);
  const formattedPrepDate = format(prepDate, "yyyy-MM-dd");

  const { data } = await axiosInstance.get("/prep", {
    params: {
      prepDate: formattedPrepDate,
    },
    headers,
  });

  return data;
}

export function usePrepList() {
  const [currentDate, setCurrentDate] = useState(new Date())
  const { getAccessTokenSilently } = useAuth0();

  const { data } = useQuery([QueryKeys.PREP_LIST, format(currentDate, 'yyyy-MM-dd')], () => fetchPrepList(currentDate, getAccessTokenSilently))

  return {
    data,
    currentDate,
    setCurrentDate
  }
}
