import Modal from "rsuite/Modal"
import Button from 'rsuite/Button'
import Loader from 'rsuite/Loader'
import { useDeleteAllFutureBuildTo } from "./hooks/useDeleteAllFutureBuildTo";

interface Props {
  open?: boolean;
  onClose?: () => void;
}

const DeleteAllConfirmationModal = ({ open, onClose }: Props) => {
  const { mutate: deleteAll, isLoading } = useDeleteAllFutureBuildTo();

  const handleConfirmation = () => {
    deleteAll(undefined, {
      onSuccess: () => {
        window.location.reload()
      },
      onError: (error) => console.error(error)
    })
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Body>
        <div className="delete-all-modal-body">
          <h4>Are you sure you want to delete all future production plans?</h4>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleConfirmation} appearance="primary" disabled={isLoading}>
          Yes
          {isLoading && <Loader />}
        </Button>
        <Button onClick={onClose} appearance="subtle">
          No
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteAllConfirmationModal