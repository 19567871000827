import { useState } from "react";
import Header from "../common/Header";
import { Loading } from "../Loading";
import Select from "@material-ui/core/Select";
import { useHistory } from "react-router-dom";
import {
  getUserStoreIdFromLocalStorage,
  getUserStoreNameFromLocalStorage,
  getUserStoresFromLocalStorage,
  setUserStoreIdToLocalStorage,
  setUserStoreNameToLocalStorage,
} from "../../helpers/localStorageHelper";

export function Stores() {
  const history = useHistory();
  let minAllowedDate = new Date();
  minAllowedDate.setMonth(minAllowedDate.getMonth() - 11);

  const [product, setProduct] = useState("");
  const [state, setState] = useState({
    error: null,
    loading: false,
  });

  const handleStoreChange = (event: any) => {
    setProduct(event.target.value);
    setUserStoreIdToLocalStorage(event.target.value);

    var userStores = getUserStoresFromLocalStorage();
    var userStoreId = getUserStoreIdFromLocalStorage();
    let obj = userStores.find((o) => o.storeId === userStoreId);
    if (obj) {
      setUserStoreNameToLocalStorage(obj.storeName);
    }

    history.push("/");
  };
  if (state.loading) {
    return <Loading />;
  }
  // const headers = ["Time", "Group", "Product", "Qty"];
  // const tableBody = ["productionTime", "group", "product", "actualQuantity"];

  var userStoreName = getUserStoreNameFromLocalStorage();
  const headerObject = {
    title: `SOUL ORIGIN (${userStoreName})`,
    subTitle: "My Stores",
  };

  const userStores = getUserStoresFromLocalStorage();

  return (
    <div className="MuiInputBase-root MuiInput-root col-md-6 lg-6">
      <div className="row ml-lg-3 mt-md-3 text-left">
        <Header {...headerObject} />
        <div className="row m-lg-3">
          <Select
            native
            value={product}
            onChange={handleStoreChange}
            label="Product"
            inputProps={{
              name: "product",
              id: "outlined-product",
            }}
          >
            <option aria-label="None" value="">
              Change Store
            </option>

            {userStores.map((item: any, index: any) => (
              <option key={index} value={item.storeId}>
                {item.storeName} - {item.storeId}
              </option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
}
