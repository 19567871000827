export enum LOCAL_STORAGE_KEYS {
  USER = "user",
  USER_STORES = "userStores",
  USER_STORE_ID = "userStoreId",
  USER_STORE_NAME = "userStoreName",
}

export interface UserStore {
  storeId: string;
  storeName: string;
  role: "planner" | "admin";
}

export const getUserFromLocalStorage = () =>
  localStorage.getItem(LOCAL_STORAGE_KEYS.USER) ?? "";

export const getUserStoreNameFromLocalStorage = () =>
  localStorage.getItem(LOCAL_STORAGE_KEYS.USER_STORE_NAME) ?? "";

export const getUserStoreIdFromLocalStorage = () =>
  localStorage.getItem(LOCAL_STORAGE_KEYS.USER_STORE_ID) ?? "";

export const getUserStoresFromLocalStorage = () =>
  JSON.parse(localStorage.getItem("userStores") ?? "[]") as UserStore[];

export const setUserStoresToLocalStorage = (userStores: UserStore[]) => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_STORES);
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.USER_STORES,
    JSON.stringify(userStores)
  );
};

export const setUserStoreNameToLocalStorage = (storeName: string) => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_STORE_NAME);
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER_STORE_NAME, storeName);
};

export const setUserStoreIdToLocalStorage = (storeId: string) => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_STORE_ID);
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER_STORE_ID, storeId);
};
