import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import {
  getUserStoresFromLocalStorage,
  setUserStoreIdToLocalStorage,
  setUserStoreNameToLocalStorage,
  setUserStoresToLocalStorage,
  UserStore,
} from "../../helpers/localStorageHelper";
import axiosInstance from "../config/axiosInstance";

const isArrayEqual = (a: unknown[], b: unknown[]) => {
  return _.isEmpty(_.differenceWith(a, b, _.isEqual));
};

async function fetchUserStores(
  getAccessTokenSilently: any
): Promise<UserStore[]> {
  const accessToken = await getAccessTokenSilently({
    authorizationParams: {
      audience: process.env.REACT_APP_AUDIENCE,
      scope: "makeapp",
    }
  });

  const response = await axiosInstance.get("/userstore", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  if (response.status === 200) {
    const serverUserStores = response.data as UserStore[];

    const localUserStores = getUserStoresFromLocalStorage();

    if (!isArrayEqual(serverUserStores, localUserStores)) {
      localStorage.clear();

      setUserStoresToLocalStorage(serverUserStores);
      setUserStoreIdToLocalStorage(serverUserStores[0].storeId);
      setUserStoreNameToLocalStorage(serverUserStores[0].storeName);
    }
  }

  return response.data;
}

export function useUserStores() {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["user-stores"], () =>
    fetchUserStores(getAccessTokenSilently)
  );
}
