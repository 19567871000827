import * as React from "react";
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
  Legend,
} from "@devexpress/dx-react-chart-bootstrap4";
import "@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css";
import moment from "moment";
import Select from "@material-ui/core/Select";
import { remove_duplicates_es6 } from "../../helpers/helper";
import { Stack, Animation } from "@devexpress/dx-react-chart";
// import { productiondata } from '../store';
import BarColor from "../../helpers/color";
import TimePicker from "./TimePicker";

const Root = (props: any) => (
  <Legend.Root {...props} className="m-auto flex-col" />
);

export default function BarChartForReport(props: any) {
  const { bcData,title } = props;
  // let barData: any = [];
  const getTimeList = (dat: any) => {
    let timeList: any = [];
    if (dat && dat.length) {
      timeList = dat.map((prod: any) => {
        return prod.productionTime;
      });
    }
    const uniq: any = remove_duplicates_es6(timeList);
    return uniq;
  };
  const getProductList = (dat: any) => {
    let productList: any = [];
    if (dat && dat.length) {
      productList = dat.map((prod: any) => {
        return prod.product;
      });
    }
    const uniq: any = remove_duplicates_es6(productList);
    return uniq;
  };

  const [timeList, setTimeList] = React.useState([]);
  const [productList, setProductList] = React.useState([]);
  const [productListBackUp, setProductListBackUp] = React.useState([]);
  const [barData, setBarData] = React.useState([]);
  const [timeBarData, setTimeBarData] = React.useState([]);
  const [barDataBackUp, setBarDataBackUp] = React.useState([]);
  const [product, setProduct] = React.useState("");
  const [fromTime, setFromTime] = React.useState(new Date());
  const [toTime, setToTime] = React.useState(new Date());
  const [showProdChart, setShowProdChart] = React.useState(false);
  React.useEffect(() => {
    setTimeList(getTimeList(bcData));
    const prod = getProductList(bcData);
    setProductList(prod);
    setProductListBackUp(prod);
  }, [bcData]);

  const calculateBarData = (prodList: any) => {
    let barDataModal: any = [];
    setFromTime(timeList[0]);
    setToTime(timeList[timeList.length - 1]);
    timeList.forEach((t: any) => {
      let barObj: any = {};
      barObj.time = moment(t).format("HH:mm");
      const productsOnTime = bcData.filter(
        (data: any) => data.productionTime === t
      );
      prodList.forEach((prod: any, index: any) => {
        const findProd = productsOnTime.find((p: any) => p.product === prod);
        barObj[`product${index}`] = findProd ? findProd.actualQuantity : 0;
      });
      barDataModal.push(barObj);
    });
    setBarData(barDataModal);
    return barDataModal;
  };
  React.useEffect(() => {
    const barDataModal = calculateBarData(productList);
    setBarDataBackUp(barDataModal);
    setTimeBarData(barDataModal);
  }, [timeList]);

  const handleProductChange = (event: any) => {
    const pVal: any = event.target.value;
    if (pVal === "" || pVal === "Select") {
      setBarData(barDataBackUp);
      return;
    }

    setProduct(pVal);
    setShowProdChart(true);
    calculateBarData([pVal]);
  };
  const handleFromTimeChange = (time: any) => {
    setFromTime(time);
    setShowProdChart(false);
    setProduct("Select");
    const filteredTime = barDataBackUp.filter(
      (t: any) =>
        t.time >= moment(time).format("HH:mm") &&
        t.time <= moment(toTime).format("HH:mm")
    );
    setTimeBarData(filteredTime);
  };
  const handleToTimeChange = (time: any) => {
    setToTime(time);
    setShowProdChart(false);
    setProduct("Select");
    const filteredTime = barDataBackUp.filter(
      (t: any) =>
        t.time >= moment(fromTime).format("HH:mm") &&
        t.time <= moment(time).format("HH:mm")
    );
    setTimeBarData(filteredTime);
  };
  const getProductBarChart = () => {
    return (
      <BarSeries
        name={product}
        valueField={`product0`}
        argumentField={"time"}
        color={BarColor[0]}
      />
    );
  };
  return (
    <React.Fragment>
      {bcData && bcData.length > 0 && (
        <div className="card bg-white">
          <div className="row m-lg-3 justify-content-left w-100 mt-5 mx-auto">
            <TimePicker
              tpLabel="From"
              selectedDate={fromTime}
              handleTimeChange={handleFromTimeChange}
            />
            <TimePicker
              tpLabel="To"
              selectedDate={toTime}
              handleTimeChange={handleToTimeChange}
            />
            <div className="col-md-4"></div>
            <Select
              className="col-md-4 mb-4"
              native
              value={product}
              onChange={handleProductChange}
              label="Product"
              inputProps={{
                name: "product",
                id: "outlined-product",
              }}
            >
              <option aria-label="None" value="">
                Select
              </option>
              {productListBackUp.map((item: string, index: any) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </Select>
          </div>
          {/* time bar chart */}
          {!showProdChart && timeBarData && timeBarData.length > 0 && (
            <Chart
              width={750}
              height={productList.length < 10 ? 500 : productList.length * 50}
              data={timeBarData}
            >
              <ArgumentAxis />
              <ValueAxis />
              {productList &&
                productList.length > 1 &&
                productList.map((prod: any, index: any) => (
                  <BarSeries
                    name={prod}
                    valueField={`product${index}`}
                    argumentField={"time"}
                    color={BarColor[index]}
                  />
                ))}
              <Animation />
              <Legend position="bottom" rootComponent={Root} />
              <Title text={title} />
              <Stack />
            </Chart>
          )}
          {/* single product bar chart */}
          {showProdChart && barData && barData.length > 0 && (
            <Chart width={750} height={500} data={barData}>
              <ArgumentAxis />
              <ValueAxis />
              {getProductBarChart()}
              <Animation />
              <Title text={title} />
              <Stack />
            </Chart>
          )}
        </div>
      )}
    </React.Fragment>
  );
}
