import { KeyboardDatePicker } from "@material-ui/pickers";
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons";
import { removeUnderScoreAndCaptalize } from "../../helpers/helper";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { SortKeys } from "./utils/sortCateringOrder";

interface Props {
  fromDate: Date;
  toDate: Date;
  setFromDate: (date: any) => void;
  setToDate: (date: any) => void;
  sortValue: SortKeys;
  onChangeSortValue: (val: SortKeys) => void;
  sortAscending: boolean;
  toggleSortDirection: () => void;
  showSummaryView: boolean;
  toggleSummaryView: () => void;
}

const FilterControls = ({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  sortValue,
  onChangeSortValue,
  sortAscending,
  toggleSortDirection,
  showSummaryView,
  toggleSummaryView,
}: Props) => {
  return (
    <div className="filter-controls d-flex justify-content-between">
      <div>
        <KeyboardDatePicker
          onChange={(date) => {
            if (isNaN(Date.parse(date as unknown as string))) {
              return;
            }
            setFromDate(date);
          }}
          value={fromDate}
          format="dd/MM/yyyy"
          label="From"
          variant="inline"
          placeholder="DD/MM/YYYY"
          style={{ marginRight: "0.5rem" }}
        />
        <KeyboardDatePicker
          onChange={(date) => {
            if (isNaN(Date.parse(date as unknown as string))) {
              return;
            }
            setToDate(date);
          }}
          value={toDate}
          format="dd/MM/yyyy"
          label="To"
          variant="inline"
          placeholder="DD/MM/YYYY"
        />
      </div>
      <div className="d-flex align-items-center">
        <div className="mr-2">
          <BootstrapSwitchButton
            width={150}
            onstyle="dark"
            offstyle="dark"
            checked={showSummaryView}
            onlabel="Summary View"
            offlabel="Detail View"
            onChange={toggleSummaryView}
          />
        </div>
        <div className="d-flex">
          <select
            className="form-select"
            value={sortValue}
            onChange={(e) => onChangeSortValue(e.target.value as SortKeys)}
            style={{ boxShadow: "none" }}
          >
            {Object.entries(SortKeys).map(([key, value]) => (
              <option value={value} key={key}>
                {`Sort By: ${removeUnderScoreAndCaptalize(key)}`}
              </option>
            ))}
          </select>
          <button
            onClick={toggleSortDirection}
            className="btn btn-outline"
            style={{ boxShadow: "none" }}
          >
            {sortAscending ? <ArrowDropUp /> : <ArrowDropDown />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterControls;
