import { useEffect, useState } from "react"
import { isAdmin, isCurrentWeek, isFutureWeek } from "../../helpers/helper"
import ActionMenuItem from "./ActionMenuItem"
import { BiSolidCopyAlt, BiSolidTrash } from "react-icons/bi"
import './ActionMenu.css'

export const MENU_OPTIONS = {
  DELETE_ALL: 'DELETE_ALL',
  COPY_FROM: 'COPY_FROM'
}

const ActionIcons = {
  [MENU_OPTIONS.DELETE_ALL]: <BiSolidTrash size={20} color="#FFB81C" />,
  [MENU_OPTIONS.COPY_FROM]: <BiSolidCopyAlt size={20} color="#FFB81C" />
}

const ActionLabels = {
  [MENU_OPTIONS.DELETE_ALL]: 'Delete All Future Plans',
  [MENU_OPTIONS.COPY_FROM]: 'Copy From Week Of'
}

interface Props {
  weekStartDate: Date,
  onSelectOption: (action: string) => void
}

const ActionMenu = ({ weekStartDate, onSelectOption }: Props) => {
  const [actions, setActions] = useState<string[]>([])

  useEffect(() => {
    let options = []

    if (isCurrentWeek(weekStartDate) && isAdmin()) {
      options.push(MENU_OPTIONS.DELETE_ALL)
    }

    if (isCurrentWeek(weekStartDate) || isFutureWeek(weekStartDate)) {
      options.push(MENU_OPTIONS.COPY_FROM)
    }

    setActions(options)

  }, [weekStartDate])

  if (!actions.length) {
    return <NoActions />
  }

  return (
    <div>
      <div className='planning-action-menu__menu'>
        <ul>
          {actions.map(action => (
            <ActionMenuItem
              icon={ActionIcons[action]}
              label={<span>{ActionLabels[action]}</span>}
              selectAction={onSelectOption}
              action={action}
              key={action}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}

const NoActions = () => {
  return (
    <div className='planning-action-menu__menu'>
      <ul>
        <li className='planning-action-menu__dummy-action'>No Actions</li>
      </ul>
    </div>
  )
}

export default ActionMenu