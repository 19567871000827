import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import { KeyboardTimePicker } from "@material-ui/pickers";

export default function TimePicker(props: any) {
  const handleTimeChange = (date: any) => {
    props.handleTimeChange(date);
  };

  return (
    <React.Fragment>
      <div className="col-md-2 mb-md-4">
        <Grid container justify="space-around">
          <KeyboardTimePicker
            margin="normal"
            format="HH:mm"
            id="time-picker"
            label={props.tpLabel}
            value={props.selectedDate}
            onChange={handleTimeChange}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
          />
        </Grid>
      </div>
    </React.Fragment>
  );
}
