import { QueryClient } from "@tanstack/react-query";

function queryErrorHandler(error: unknown): void {
  // error is type unknown because in js, anything can be an error (e.g. throw(5))
  const msg =
    error instanceof Error ? error.message : "error connecting to server";

  console.log(msg)
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: queryErrorHandler,
      retry: 0,
      // staleTime: 600000,
      // refetchOnMount: false,
      // refetchOnReconnect: false,
      refetchOnWindowFocus: false
    },
  },
});
