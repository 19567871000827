import * as React from "react";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "../common/Header";
import DatePicker from "../common/DatePicker";
import ReportTable from "../common/ReportTable";
import { Button } from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import { AdminRedirect, remove_duplicates_es6 } from "../../helpers/helper";
import { Loading } from "../Loading";
import {
  highVarianceByGroup,
  highVarianceByDate,
} from "../../handlers/reports";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import moment from "moment";
import { dummyDataHVR1, dummyDataHVR2 } from "../../helpers/mockData";
import { getUserStoreNameFromLocalStorage } from "../../helpers/localStorageHelper";

const API = process.env.REACT_APP_AUDIENCE || 3001;
export function HighVarianceReport() {
  // AdminRedirect();
  const noData: any = [];
  const { getAccessTokenSilently } = useAuth0();
  let currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 11);
  const [reportData, setReportData] = useState([]);
  const [databackUp, setDataBackup]: any = useState([]);
  const [reportData2, setReportData2] = useState([]);
  const [databackUp2, setDataBackup2]: any = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [productData, setProductData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [fromSelectedDate, setFromSelectedDate] = React.useState(new Date());
  const [minFromSelectedDate, setMinFromSelectedDate] =
    React.useState(currentDate);
  const [minToSelectedDate, setMinToSelectedDate] = React.useState(currentDate);
  const [maxFromSelectedDate, setMaxFromSelectedDate] = React.useState(
    new Date()
  );
  const [maxToSelectedDate, setMaxToSelectedDate] = React.useState(new Date());
  const [product, setProduct] = useState("");
  const [csvData, setCSVData] = useState([]);
  const [group, setGroup] = useState("");
  const [msg, setMsg] = useState("");
  const [toSelectedDate, setToSelectedDate] = React.useState(new Date());
  const [state, setState] = useState({
    error: null,
    loading: false,
  });
  const [order, setorder] = useState("ASC");
  const handleFromDateChange = (date: any) => {
    let enteredDate = new Date(date);
    enteredDate.setMonth(enteredDate.getMonth() + 1);
    setFromSelectedDate(date);
    setMinToSelectedDate(date);
    if (
      moment(enteredDate).format("YYYY-MM-DD") <=
      moment(new Date()).format("YYYY-MM-DD")
    ) {
      setMaxToSelectedDate(enteredDate);
      setToSelectedDate(enteredDate);
    } else {
      setMaxToSelectedDate(new Date());
      setToSelectedDate(new Date());
    }
  };
  const handleToDateChange = (date: any) => {
    let enteredDate = new Date(date);
    enteredDate.setMonth(enteredDate.getMonth());
    setToSelectedDate(date);
  };
  const getProductList = (dat: any) => {
    let productList: any = [];
    if (dat && dat.length) {
      productList = dat.map((prod: any) => {
        return prod.product;
      });
    }
    const uniq: any = remove_duplicates_es6(productList);
    setProductData(uniq);
  };
  const getGroupList = (dat: any) => {
    let groupList: any = [];
    if (dat && dat.length) {
      groupList = dat.map((prod: any) => {
        return prod.group;
      });
    }
    const uniq: any = remove_duplicates_es6(groupList);
    setGroupData(uniq);
  };
  const getCSVData = (dat: any) => {
    let csvData: any = [];
    if (dat && dat.length) {
      csvData = dat.map((prod: any) => {
        return {
          ...prod,
          productionDate: moment(prod.productionDate).format("DD/MM/YYYY"),
        };
      });
    }
    const uniq: any = remove_duplicates_es6(csvData);
    setCSVData(uniq);
  };
  const generateReport1 = async () => {
    try {
      setState({ ...state, error: null, loading: true });
      let dat = await highVarianceByGroup(
        fromSelectedDate,
        toSelectedDate,
        getAccessTokenSilently
      );
      const highVarianceReportViewObj = dat && dat.highVarianceReportViewModels;
      const highVarianceProductNoteViewModels =
        dat && dat.highVarianceProductNoteViewModels;
      if (
        !highVarianceReportViewObj ||
        (highVarianceReportViewObj && highVarianceReportViewObj.length === 0)
      ) {
        setReportData([]);
        setDataBackup([]);
        setMsg("No Records Found.");
      }
      if (
        !highVarianceProductNoteViewModels ||
        (highVarianceProductNoteViewModels &&
          highVarianceProductNoteViewModels.length === 0)
      ) {
        setReportData2([]);
        setDataBackup2([]);
        setMsg("No Records Found.");
      }

      setReportData(highVarianceReportViewObj);
      setDataBackup(highVarianceReportViewObj);
      setReportData2(highVarianceProductNoteViewModels);
      setDataBackup2(highVarianceProductNoteViewModels);
      getProductList(highVarianceReportViewObj);
      getGroupList(highVarianceReportViewObj);
      getCSVData(highVarianceProductNoteViewModels);
      setState({ ...state, error: null, loading: false });
    } catch (error:any) {
      setReportData([]);
      setDataBackup([]);
      setReportData2([]);
      setDataBackup2([]);
      setState({ ...state, error, loading: false });
    }
  };
  
  const generateReport = () => {
    generateReport1();
  };
  const onSearchData = (value: string) => {
    if (value == "") {
      setReportData(databackUp);
      return;
    }
    const lowercasedFilter = value.toLowerCase();
    const result = databackUp.filter(
      (el: any) =>
        el.product.toLowerCase().includes(lowercasedFilter) ||
        el.group.toLowerCase().includes(lowercasedFilter)
    );
    var uniq: any = remove_duplicates_es6(result);
    setReportData(uniq);
    //todo
  };
  const onClearSearch = () => {
    setSearchValue("");
    setReportData(databackUp);
    //todo
  };
  const handleProductChange = (event: any) => {
    setProduct(event.target.value);
    if (event.target.value === "" || event.target.value === "Select") {
      setReportData(databackUp);
      return;
    }
    const filteredProduct = databackUp.filter(
      (prod: any) => prod.product === event.target.value
    );
    setReportData(filteredProduct);
  };
  const handleGroupChange = (event: any) => {
    setGroup(event.target.value);
    if (event.target.value === "" || event.target.value === "Select") {
      setReportData(databackUp);
      return;
    }
    const filteredGroup = databackUp.filter(
      (prod: any) => prod.group === event.target.value
    );
    setReportData(filteredGroup);
  };
  if (state.loading) {
    return <Loading />;
  }
  const headers1 = [
    "Group",
    "Product",
    "Days with Actual > Planned",
    "Days with Planned > Actual",
  ];
  const tableBody1 = [
    "group",
    "product",
    "daysWithActualLessThanPlanned",
    "daysWithActualMoreThanPlanned",
  ];
  const headers2 = ["Date", "Product", "Notes"];
  const tableBody2 = ["productionDate", "product", "note"];

  var userStoreName = getUserStoreNameFromLocalStorage();
  const headerObject = {
    title: `SOUL ORIGIN (${userStoreName})`,
    subTitle: "High Variance Report",
  };

  const sortTable = (sortedColumn: any) => {
    if (order === "ASC") {
      const sortedData = [...reportData].sort((a: any, b: any) =>
        a[sortedColumn] > b[sortedColumn] ? 1 : -1
      );
      setReportData(sortedData);
      setorder("DESC");
    }
    if (order === "DESC") {
      const sortedData = [...reportData].sort((a: any, b: any) =>
        a[sortedColumn] < b[sortedColumn] ? 1 : -1
      );
      setReportData(sortedData);
      setorder("ASC");
    }
  };
  return (
    <React.Fragment>
      <div className="row ml-lg-3 mt-md-3 text-left">
        <Header {...headerObject} />
        <div>
          <div className="row m-lg-3 justify-content-left w-100 mt-5 mx-auto">
            <DatePicker
              dpLabel="From Date"
              selectedDate={fromSelectedDate}
              handleDateChange={handleFromDateChange}
              minAllowedDate={minFromSelectedDate}
              maxAllowedDate={maxFromSelectedDate}
            />
            <DatePicker
              dpLabel="To Date"
              selectedDate={toSelectedDate}
              handleDateChange={handleToDateChange}
              minAllowedDate={minToSelectedDate}
              maxAllowedDate={maxToSelectedDate}
            />
            <React.Fragment>
              <div className="col-md-3 mb-md-4 mt-md-3">
                <Button
                  onClick={generateReport}
                  variant="contained"
                  color="primary"
                  className="btn btn-primary ctm-btn"
                >
                  Generate Report
                </Button>
              </div>
            </React.Fragment>
          </div>
          {databackUp.length === 0 && (
            <div className="no-rcd">
              <p>{msg}</p>{" "}
            </div>
          )}
          {databackUp.length > 0 && (
            <div>
              <SearchBar
                value={searchValue}
                className="w-100 ml-auto border br-8 shade-0 my-5"
                onChange={onSearchData}
                onCancelSearch={onClearSearch}
              />
              <FormControl variant="outlined" className="col col-lg-6">
                <InputLabel htmlFor="outlined-product">Product</InputLabel>
                <Select
                  native
                  value={product}
                  onChange={handleProductChange}
                  label="Product"
                  inputProps={{
                    name: "product",
                    id: "outlined-product",
                  }}
                >
                  <option aria-label="None" value="" />
                  {productData.map((item: string, index: any) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className="col col-lg-6">
                <InputLabel htmlFor="outlined-group">Group</InputLabel>
                <Select
                  native
                  value={group}
                  onChange={handleGroupChange}
                  label="Group"
                  inputProps={{
                    name: "group",
                    id: "outlined-group",
                  }}
                >
                  <option aria-label="None" value="" />
                  {groupData.map((item: string, index: any) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {reportData.length > 0 && (
                <ReportTable
                  sortTable={sortTable}
                  tbName="High_Variance_Report_By_Group"
                  tbHeaders={headers1}
                  tbData={reportData}
                  csvData={reportData}
                  tbBody={tableBody1}
                  dtFormat="DD/MM/YYYY"
                />
              )}
              {reportData2.length > 0 && (
                <ReportTable
                  sortTable={sortTable}
                  tbName="High_Variance_Report_By_Date"
                  tbHeaders={headers2}
                  tbData={reportData2}
                  csvData={csvData}
                  tbBody={tableBody2}
                  dtFormat="DD/MM/YYYY"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
