import { useIsMutating } from "@tanstack/react-query";
import _, { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { getUserStoreNameFromLocalStorage } from "../../helpers/localStorageHelper";
import { ZERO_ID } from "../../shared/constants/constants";
import { ITakeHomeSpecial } from "../../shared/models/TakeHomeSpecial";
import { MutationKeys } from "../../shared/react-query/mutationKeys";
import CategoryFilterButtons from "../common/CategoryFilterButtons";
import Header from "../common/Header";
import TakeHomeGrid from "./components/TakeHomeGrid";
import { useTakeHomeSpecials } from "./hooks/useTakeHomeSpecials";
import { useUpdateTakeHomeSpecials } from "./hooks/useUpdateTakeHomeSpecials";

const isFresh = (product: ITakeHomeSpecial) => {
  return product.id === ZERO_ID;
};

const containsFresh = (products: ITakeHomeSpecial[]) => {
  const index = products.findIndex((product) => isFresh(product));
  if (index !== -1) {
    return true;
  }
  return false;
};

const TakeHomeSpecial = () => {
  const { data, date, setDate } = useTakeHomeSpecials();
  const [takeHomeSpecialsData, setTakeHomeSpecialsData] = useState<
    ITakeHomeSpecial[]
  >([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showTabs, setShowTabs] = useState(true);

  const pendingRequests = useRef<{ [key: string]: ITakeHomeSpecial }>(
    {}
  ).current;

  const { mutate: updateTakeHome, data: mutationResult } = useUpdateTakeHomeSpecials();
  const isUpdatingTakeHome = useIsMutating([MutationKeys.TAKE_HOME]);

  const debouncedSave = useRef(
    debounce((changedProducts: ITakeHomeSpecial[]) => {
      updateTakeHome(changedProducts);
      changedProducts.forEach((product) => (product.isDirty = false));
    }, 1000)
  ).current;

  useEffect(() => {
    if (_.isEmpty(data)) {
      return;
    }
    setTakeHomeSpecialsData(data);
  }, [data]);

  useEffect(() => {
    const changedProducts = takeHomeSpecialsData.filter(
      (product) => product.isDirty
    );

    if (containsFresh(changedProducts) && isUpdatingTakeHome > 0) {
      changedProducts.forEach((product) => {
        pendingRequests[product.productId] = product;
      });
      // console.log(`blocking req for data: `, changedProducts);
      return;
    }

    if (changedProducts.length === 0) {
      return;
    }
    debouncedSave(changedProducts);
  }, [takeHomeSpecialsData, debouncedSave]);

  useEffect(() => {
    if (!mutationResult) {
      return;
    }

    const updatedRows = [...mutationResult] as ITakeHomeSpecial[];

    let updateIdsForRows: ITakeHomeSpecial[] = [];
    let updateLocalState = false;

    updatedRows.forEach((product) => {
      if (product.productId in pendingRequests) {
        updateLocalState = true;
        updateIdsForRows.push({
          ...pendingRequests[product.productId],
          id: product.id,
          isDirty: true,
        });
        delete pendingRequests[product.productId];
      }
    });

    // create filtered data
    if (!updateLocalState) {
      return;
    }

    // console.log(`updating local state for new ids`);
    const newTakeHomeData = [...takeHomeSpecialsData];
    updateIdsForRows.forEach((updated) => {
      const index = newTakeHomeData.findIndex(
        (product) => product.productId === updated.productId
      );
      if (index !== -1) {
        newTakeHomeData[index] = updated;
      }
    });
    setTakeHomeSpecialsData(newTakeHomeData);
  }, [mutationResult]);

  const onProductChange = (newProduct: ITakeHomeSpecial) => {
    const newData = [...takeHomeSpecialsData];
    const index = newData.findIndex(
      (product) => product.productId === newProduct.productId
    );

    if (index === -1) {
      return;
    }

    if (isFresh(newProduct)) {
      pendingRequests[newProduct.productId] = newProduct;
    }

    newData[index] = newProduct;

    setTakeHomeSpecialsData(newData);
  };

  const onSelectCategory = (categoryName: string) => {
    if (selectedCategory === categoryName) {
      setSelectedCategory("");
    } else {
      setSelectedCategory(categoryName);
    }
  };

  var userStoreName = getUserStoreNameFromLocalStorage();
  const headerObject = {
    title: `SOUL ORIGIN (${userStoreName})`,
    subTitle: "Take Home Specials",
  };
  return (
    <>
      <div className="row ml-lg-3 mt-md-3 text-left">
        <Header {...headerObject} />
        <CategoryFilterButtons
          showTabs={showTabs}
          onShowTabsChange={() => setShowTabs(!showTabs)}
          selectedCategory={selectedCategory}
          onCategoryChange={onSelectCategory}
        />
        <TakeHomeGrid
          takeHomeSpecialsData={takeHomeSpecialsData}
          onProductChange={onProductChange}
          prodDate={date}
          setProdDate={(newDate: Date) => setDate(newDate)}
          selectedCategory={selectedCategory}
        />
      </div>
    </>
  );
};

export default TakeHomeSpecial;
