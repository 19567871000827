import withAdminOnlyAccess from "../common/hoc/withAdminOnlyAccess";
import PrepListSettings from "./PrepListSettings";
import Nav from "rsuite/Nav";
import { useState } from "react";
import Header from "../common/Header";
import { getUserStoreNameFromLocalStorage } from "../../helpers/localStorageHelper";
import ProductPrepItems from "./ProductPrepItems";

const EVENT_KEYS = {
  PREP: "PREP",
  PRODUCT_PREP_ITEMS: "PRODUCT_PREP_ITEMS",
};

const TABS = [
  { label: "Prep List", eventKey: EVENT_KEYS.PREP },
  { label: "Product Prep Items", eventKey: EVENT_KEYS.PRODUCT_PREP_ITEMS },
];

const TAB_BODY = {
  [EVENT_KEYS.PREP]: PrepListSettings,
  [EVENT_KEYS.PRODUCT_PREP_ITEMS]: ProductPrepItems,
};
const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState(EVENT_KEYS.PREP);

  const Body = TAB_BODY[activeTab];

  var userStoreName = getUserStoreNameFromLocalStorage();
  const headerObject = {
    title: `SOUL ORIGIN (${userStoreName})`,
    subTitle: "Admin Dashboard",
  };

  return (
    <div className="so-prep-list-settings row ml-lg-3 mt-md-3">
      <Header {...headerObject} />
      <div className="w-100 mt-2 mx-auto">
        <div className="m-lg-3 mx-0 row t-shade">
          <div className="bg-white pt-2">
            <Nav
              appearance="tabs"
              activeKey={activeTab}
              onSelect={setActiveTab}
            >
              {TABS.map((tab) => (
                <Nav.Item eventKey={tab.eventKey} key={tab.eventKey}>
                  {tab.label}
                </Nav.Item>
              ))}
            </Nav>
            <div>{Body ? <Body /> : null}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAdminOnlyAccess(AdminDashboard);
