import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { PrepList } from "../../../shared/models/PrepList";
import { getHeaders } from "../../../helpers/helper";
import axiosInstance from "../../../shared/config/axiosInstance";
import { QueryKeys } from "../../../shared/react-query/queryKeys";

async function fetchPrepListSettings(getAccessTokenSilently: any): Promise<PrepList> {
  const headers = await getHeaders(getAccessTokenSilently);
  
  const { data } = await axiosInstance.get('/admin/GetPrepList', {
    headers
  })

  return data;
}

export function usePrepListSettings() {
   const { getAccessTokenSilently } = useAuth0();

   return useQuery([QueryKeys.PREP_LIST_SETTINGS], () => fetchPrepListSettings(getAccessTokenSilently))
}