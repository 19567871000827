import { format, getISOWeek, getYear, isAfter } from "date-fns";
import { useState } from "react";
import Modal from "rsuite/Modal";
import Button from 'rsuite/Button'
import Loader from "rsuite/Loader";
import { TbArrowBigRightLines } from 'react-icons/tb'
import WeekPicker from "../common/WeekPicker";
import { IWeekInfo } from "../../shared/models/IWeekInfo";
import { queryClient } from "../../shared/react-query/queryClient";
import { QueryKeys } from "../../shared/react-query/queryKeys";
import { useCopyDataFromPrevWeek } from "./hooks/useCopyDataFromPrevWeek";
import WeekInfo from "./WeekInfo";
import './CopyFromModal.css'


interface Props {
  open?: boolean;
  onClose: () => void;
  futureWeekDate: Date
}

const CopyFromModal = (props: Props) => {
  const [pastWeekDate, setPastWeekDate] = useState<Date | null>(null)
  const [isConfirmationShown, setIsConfirmationShown] = useState(false)

  const { mutate: copyPrevWeek, isLoading } = useCopyDataFromPrevWeek();

  const handleConfirmation = () => {
    if (!pastWeekDate) return 

    const data: IWeekInfo = {
      fromWeek: getISOWeek(pastWeekDate),
      fromYear: getYear(pastWeekDate),
      toWeek: getISOWeek(props.futureWeekDate),
      toYear: getYear(props.futureWeekDate)
    }

    copyPrevWeek(data, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.PRODUCTION_PLANNING, format(props.futureWeekDate, "yyyy/MM/dd")],
          refetchType: 'active',
        }, {
          throwOnError: true,
        }).then(() => {
          setIsConfirmationShown(false)
          if (props.onClose) {
            props.onClose()
          }
        })
      }
    })
  }

  return (
    <>
    <Modal open={props.open} onClose={(e) => {
      props.onClose()
      e.stopPropagation()
    }}>
      <Modal.Header>
        <h3>Copy From Past Week</h3>
      </Modal.Header>
      <Modal.Body>
        <div className='copy-from-body'>
          <WeekPicker
            value={pastWeekDate}
            handleChange={(date) =>{
              if(isAfter(date!, props.futureWeekDate)) {
                return;
              }
              setPastWeekDate(date)
            }}
            placeholder='Select Week'
            shouldDisableDate={(date) => isAfter(date, props.futureWeekDate)}
          />
          {pastWeekDate && <WeekInfo date={pastWeekDate} />}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setIsConfirmationShown(true)} appearance="primary" disabled={!pastWeekDate}>
          Ok
        </Button>
        <Button onClick={props.onClose} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
    <Modal open={isConfirmationShown} onClose={() => setIsConfirmationShown(false)}>
      <Modal.Body>
        <div className="confirmation-modal-body">
          <h4>Are you sure you want to copy planning data as shown below?</h4>
          <div className='confirmation-modal-body__weeks'>
            <WeekInfo date={pastWeekDate!} className="prev-week" />
            <TbArrowBigRightLines size={30} color="#FFB81C" />
            <WeekInfo date={props.futureWeekDate} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleConfirmation} appearance="primary" disabled={!pastWeekDate || isLoading}>
          Yes
          {isLoading && <Loader />}
        </Button>
        <Button onClick={() => setIsConfirmationShown(false)} appearance="subtle">
          No
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default CopyFromModal;
