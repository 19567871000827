import React from 'react'
import notFound from '../../assets/images/not-found.jpg'

const NotFound = () => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
        <img alt="not found" src={notFound} style={{height: '80%', maxWidth: '400px', margin: '0 auto'}} />
        <h2 style={{fontFamily: ''}}>No Data Found</h2>
    </div>
  )
}

export default NotFound