import { addDays, differenceInDays, format } from "date-fns";
import { CateringOrder } from "../../shared/models/CateringOrder";

interface ISummaryView {
  cateringOrders: CateringOrder[];
  fromDate: Date;
  toDate: Date;
}

const SummaryView = ({ cateringOrders, fromDate, toDate }: ISummaryView) => {
  const getOrderFreq = () => {
    const freq: any = {};
    for (
      var i = fromDate;
      differenceInDays(toDate, i) >= 0;
      i = addDays(i, 1)
    ) {
      let ordersInDate = cateringOrders.filter(
        (elem) => differenceInDays(i, new Date(elem.fulfillmentDate)) === 0
      );

      if (ordersInDate.length > 0) {
        let totalItemQuantity = ordersInDate
          .map((order) => {
            let count = 0;
            order.lineItems.forEach((item) => {
              count += +item.fulfillableQuantity;
            });
            return count;
          })
          .reduce((sum, val) => sum + val, 0);

        freq[format(i, "dd-MM-yyyy")] = {
          orderCount: ordersInDate.length,
          quantity: totalItemQuantity,
        };
      }
    }
    return freq;
  };

  return (
    <table
      className="table table-striped table-bordered"
      style={{ textAlign: "center" }}
    >
      <thead className="thead-dark">
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Order Count</th>
          <th scope="col">Quantities</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(getOrderFreq()).map(([orderDate, value]) => (
          <tr>
            <th scope="row">{orderDate}</th>
            <td>{(value as any).orderCount}</td>
            <td>{(value as any).quantity}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SummaryView;
