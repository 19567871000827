import { isToday } from "date-fns";
import { ITakeHomeSpecial } from "../../../shared/models/TakeHomeSpecial";
import TakeHomeRow from "./TakeHomeRow";

interface Props {
  data: ITakeHomeSpecial[];
  onChange: (product: ITakeHomeSpecial) => void;
  dateValue: Date;
}

const TakeHomeRows = ({ data, onChange, dateValue }: Props) => {
  return (
    <>
      <div className="mx-1 row bg-header my-0 py-3 rounded mt-3">
        <div className="col-2 text-center">
          <h5 className="mb-0">ITEMS</h5>
        </div>
        <div className="col-1 text-center">
          <h5 className="mb-0">RUN OUT</h5>
        </div>
        <div className="col-5 col-lg-4 col-xl-5 text-center">
          <h5 className="mb-0">TAKE ME HOME</h5>
        </div>
        <div className="col-3 col-lg-4 col-xl-3 text-center">
          <h5 className="mb-0">WASTE</h5>
        </div>
        <div className="col-1 text-center">
          <h5 className="mb-0">NOTES</h5>
        </div>
      </div>
      {data.map((item) => (
        <TakeHomeRow
          key={item.productId}
          product={item}
          onChange={onChange}
          isDisabled={!isToday(dateValue)}
          dateValue={dateValue}
        />
      ))}
    </>
  );
};

export default TakeHomeRows;
