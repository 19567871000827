import moment from "moment";
import { getUserStoreIdFromLocalStorage, getUserStoresFromLocalStorage } from "./localStorageHelper";
import { isAfter, startOfISOWeek } from "date-fns";
import { isEqual } from "lodash";

export const getCurrentWeek = () => {
  const todaydate: any = new Date();
  moment.updateLocale("en", { week: {
    dow: 1, // First day of week is Monday
    doy: 7  // First week of year must contain 1 January (7 + 1 - 1)
  }});
  var weeknumber = moment(todaydate, "DD/MM/YYYY").week();
  return weeknumber;
};

export const getWeekDays = (weekNumber: any, year: any) => {
 
  var firstDay = new Date(year, 0, 1).getDay();
  var d = new Date("Jan 01, " + year + " 01:00:00");
  var w = d.getTime() - 3600000 * 24 * (firstDay - 1) + 604800000 * (weekNumber - 1);
  var firstday = moment(new Date(w)).format("DD/MM/YYYY");
  var lastday = moment(new Date(w + 518400000)).format("DD/MM/YYYY");
  return `${firstday} - ${lastday}`;
};

export const remove_duplicates_es6 = (arr: any) => {
  let uniques = Array.from(new Set(arr)).filter(function (el) {
    return el != null;
  });
  return uniques;
};

export const getCurrentDate = () => {
  const today = new Date()
  return {sample:today, formated:moment(today).format("DD/MM/YYYY")}

};

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};
export const getHeaders = async (getAccessTokenSilently : any) => {
  const userStoreId= getUserStoreIdFromLocalStorage();
  const accessToken = await getAccessTokenSilently({
    authorizationParams: {
      audience: process.env.REACT_APP_AUDIENCE,
      scope: "makeapp",
  }
  });
  const headers = {
      Authorization: `Bearer ${accessToken}`,
      storeId: userStoreId
  };
  return headers;
};


export const AdminRedirect = () => {
  var userStoreId = getUserStoreIdFromLocalStorage();

  if(userStoreId==="9999")
  {

    var url = window.location.href;
    var arr = url.split("/");
  
    var result = arr[0] + "//" + arr[2];
    window.location.href = result + "/#Admin";
  }
};

export const NonAdminRedirect = () => {
  var userStoreId = getUserStoreIdFromLocalStorage();

  if(userStoreId!=="9999")
  {

    var url = window.location.href;
    var arr = url.split("/");
  
    var result = arr[0] + "//" + arr[2];
    window.location.href = result;
  }
};

export const removeUnderScoreAndCaptalize = (word: string) => {
  return word
    .split("_")
    .map((e) => {
      let lowered = e.toLowerCase();
      return lowered[0].toUpperCase() + lowered.substring(1);
    })
    .join(" ");
};

export const getTodayDateWithoutTime = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), today.getDate());
}

export const isFutureWeek = (date: Date) => {
  const currentWeekStartDate = startOfISOWeek(new Date())
  const weekStartDate = startOfISOWeek(date)

  return isAfter(weekStartDate, currentWeekStartDate)
}

export const isCurrentWeek = (date: Date) => {
  const currentWeekStartDate = startOfISOWeek(new Date())
  const weekStartDate = startOfISOWeek(date)

  return isEqual(weekStartDate, currentWeekStartDate)
}

export const isAdmin = () => {
  const userStoreId = getUserStoreIdFromLocalStorage();
  const userStores = getUserStoresFromLocalStorage();

  const currentStore = userStores.find(
    (store) => store.storeId === userStoreId
  );

  if (!currentStore) {
    return false;
  }

  if (currentStore.role !== "admin") {
    return false;
  }

  return true;
};