import { useState, useEffect } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core"
import Button from "@material-ui/core/Button";
import { PiNoteBlank } from "react-icons/pi";

interface Props {
  open: boolean
  hide: () => void
  editable: boolean
  value?: string
  savingNotesInProgess: boolean
  handleSubmit: (note: string, hideDialogAfterSave: () => void) => void
  show: () => void
}

const PrepListNotes = ({ open, hide, value, savingNotesInProgess, handleSubmit, show }: Props) => {
  const [note, setNote] = useState("")

  useEffect(() => {
    setNote(value || "")
  }, [value])

  const handleAdd = () => {
    handleSubmit(note, () => hide())
  }

  const getNotePreview = () => {
    if (!value) return

    if (value.length <= 10) return value;

    return value.substring(0, 8) + "...";
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={hide}
        aria-labelledby="prep-list-note"
      >
        <div style={{ width: 600 }}>
          <DialogTitle id="prep-list-note">Notes</DialogTitle>
          <DialogContent>
            <TextField
              value={note}
              autoFocus
              margin="dense"
              label="Note"
              type="textarea"
              multiline
              rowsMax="10"
              fullWidth
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length
                )
              }
              onChange={(event) => setNote(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={hide} color="primary">
              Cancel
            </Button>
              <Button onClick={handleAdd} color="primary" disabled={value === note}>
                {savingNotesInProgess && (
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Add
              </Button>
          </DialogActions>
        </div>
      </Dialog>
      <div onClick={show} style={{cursor: 'pointer'}}>
        {value
          ? <span>{getNotePreview()}</span>
          : <PiNoteBlank size={37} />}
      </div>
    </div>
  )
}

export default PrepListNotes