import { PrepArticle } from "../../shared/models/PrepList";
import { useRef, useState } from "react";
import { format, set } from "date-fns";
import FileGuide from "../Production/components/FileGuide";
import PrepListNotes from "./PrepListNotes";
import { useIsMutating } from "@tanstack/react-query";
import { MutationKeys } from "../../shared/react-query/mutationKeys";

interface Props {
  item: PrepArticle;
  onChange: (item: PrepArticle, successCb?: () => void, errorCb?: () => void) => void;
  currentDate: Date;
  isDisabled?: boolean;
}

const PrepListRow = ({ item, currentDate, onChange, isDisabled }: Props) => {
  const [isNoteDialogShown, setIsNoteDialogShown] = useState(false)
  const isUpdatingProduction = useIsMutating([MutationKeys.PREP]);


  
  const timerRef = useRef<HTMLInputElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const inputRef = useRef<HTMLButtonElement>(null);

  const runOutClicked = (rowData: PrepArticle) => {
    const today = new Date();
    const time = format(today, "HH:mm");

    if (labelRef.current !== null && inputRef.current !== null) {
      labelRef.current.innerText = time;
      inputRef.current.style.display = "none";
      labelRef.current.style.display = "block";
    }

    const updated = {
      ...rowData,
      completedTime: new Date(today).toISOString(),
    };
    onChange(updated);
  };

  const displayTimer = () => {
    if (!isDisabled &&
      labelRef.current !== null &&
      timerRef.current !== null
    ) {
      labelRef.current.style.display = "none";
      timerRef.current.style.display = "block";
      timerRef.current.value = labelRef.current.innerText;
    }
  };

  const changeTimer = (rowData: PrepArticle) => {
    if (timerRef.current !== null && labelRef.current !== null) {
      labelRef.current.innerText = timerRef.current.value;
      timerRef.current.style.display = "none";
      labelRef.current.style.display = "block";

      const [hours, minutes] = timerRef.current.value.split(":");
      const newDate = set(new Date(), {
        hours: +hours,
        minutes: +minutes,
      });
      const updated = { ...rowData, completedTime: newDate.toISOString() };
      onChange(updated);
    }
  };

  const handleNotesSave = (note: string, hideDialogAfterSave: () => void) => {
    onChange({
      ...item,
      notes: note + '\n'
    }, hideDialogAfterSave, hideDialogAfterSave)
  }
  return (
    <div
      className="mx-1 row shade-row my-0 py-2 rounded mt-3 table-font"
      key={`${item.timeOfDay}-${item.ingredientPrepItem}`}
    >
      <div className="col-4 text-center my-auto">{item.prepItem}</div>
      <div className="col-2 text-center my-auto">{item.quantity}</div>
      <div className="col-1 text-center my-auto">{item.uoM}</div>
      <div className="col-2 text-center my-auto">
        <button
          className="bg.-sec ctm-btn mx-auto"
          ref={inputRef}
          style={{
            display: item.completedTime ? "none" : "block",
            borderRadius: "5px",
            backgroundColor: "#FFB81C",
            color: "White",
            width: "80px",
            opacity: isDisabled ? "0.5" : "1",
          }}
          onClick={() => runOutClicked(item)}
          value={"00:00"}
          disabled={isDisabled}
        >
          Done
        </button>
        <label
          style={{
            display: item.completedTime ? "block" : "none",
          }}
          onClick={displayTimer}
          id={`done_label`}
          ref={labelRef}
        >
          {item.completedTime
            ? format(new Date(item.completedTime), "HH:mm")
            : "00:00"}
        </label>

        <input
          style={{ display: "none", width: "100px" }}
          type="time"
          onBlur={() => changeTimer(item)}
          id={`done_input`}
          ref={timerRef}
          title=""
          className="mx-auto"
        ></input>
      </div>
      <div className="col-1 text-center my-auto">
        <FileGuide PLU={item.prepGuide} guideUrl="/fileguide/Getprepguide" iconSize={35} />
      </div>
      <div className="col-2 text-center my-auto">
        <PrepListNotes
          open={isNoteDialogShown}
          hide={() => setIsNoteDialogShown(false)}
          show={() => setIsNoteDialogShown(true)}
          editable
          handleSubmit={handleNotesSave}
          savingNotesInProgess={isUpdatingProduction > 0}
          value={item.notes}
        />
      </div>
    </div>
  );
};

export default PrepListRow;
