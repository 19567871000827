import { useState, useMemo } from "react";
import { PrepArticle, TimeOfDay } from "../../shared/models/PrepList";
import ToolBar from "../common/ToolBar";
import PrepListTable from "./PrepListTable";
import _ from "lodash";
import { MultiSelect } from "react-multi-select-component";
import Stack from "rsuite/Stack";
import Button from "rsuite/Button";
import { isFuture, isToday } from "date-fns";

interface Props {
  prepList?: PrepArticle[];
  currentDate: Date;
  onDateChange: (date: Date) => void;
  onPrepItemChange: (
    item: PrepArticle,
    successCb?: () => void,
    errorCb?: () => void
  ) => void;
}

export interface PrepGroupedByIngredient {
  [key: string]: PrepArticle[];
}

type PrepGroupedByTimeOfDay = {
  [key in TimeOfDay]: PrepArticle[];
};

type CategorisedList = {
  [key in TimeOfDay]: PrepGroupedByIngredient;
};

const TIME_OF_DAY_OPTIONS = [
  { label: "Morning", value: "Morning" },
  { label: "Afternoon", value: "Afternoon" },
];

const PrepListGrid = ({
  currentDate,
  onDateChange,
  prepList,
  onPrepItemChange,
}: Props) => {
  const [searchText, setSearchText] = useState("");
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState([]);
  const [bufferPercentage, setBufferPercentage] = useState(0);

  const categorisedPrepList = useMemo(() => {
    if (!prepList || _.isEmpty(prepList)) return;

    let list = _.cloneDeep(prepList);
    list = list.map((item) =>
      item.completedTime
        ? item
        : {
            ...item,
            quantity: Math.ceil(
              item.quantity + (bufferPercentage / 100) * item.quantity
            ),
          }
    );

    const groupedByTimeOfDay = list.reduce((obj, currentVal) => {
      const { timeOfDay } = currentVal;
      obj[timeOfDay] = obj[timeOfDay]
        ? [...obj[timeOfDay], currentVal]
        : [currentVal];

      return obj;
    }, {} as PrepGroupedByTimeOfDay);

    const categorisedList: CategorisedList = {
      Morning: {} as PrepGroupedByIngredient,
      Afternoon: {} as PrepGroupedByIngredient,
    };

    if (groupedByTimeOfDay["Morning"]) {
      categorisedList["Morning"] = groupedByTimeOfDay["Morning"].reduce(
        (obj, prep) => {
          const { ingredient } = prep;
          obj[ingredient] = obj[ingredient]
            ? [...obj[ingredient], prep]
            : [prep];

          return obj;
        },
        {} as PrepGroupedByIngredient
      );
    }

    if (groupedByTimeOfDay["Afternoon"]) {
      categorisedList["Afternoon"] = groupedByTimeOfDay["Afternoon"].reduce(
        (obj, prep) => {
          const { ingredient } = prep;
          obj[ingredient] = obj[ingredient]
            ? [...obj[ingredient], prep]
            : [prep];

          return obj;
        },
        {} as PrepGroupedByIngredient
      );
    }

    console.log(categorisedList);
    return categorisedList;
  }, [prepList, bufferPercentage]);

  const getFilteredData = (timeOfDay: TimeOfDay) => () => {
    if (!categorisedPrepList) return [] as unknown as PrepGroupedByIngredient;
    if (!searchText) return categorisedPrepList[timeOfDay];

    let filtered = _.cloneDeep(categorisedPrepList[timeOfDay]);

    Object.keys(filtered).forEach((key) => {
      filtered[key] = filtered[key].filter((prep) =>
        prep.prepItem.toLowerCase().includes(searchText.toLowerCase())
      );
    });

    // if (searchText) {
    //   filtered = filtered.filter((item) =>
    //     item.ingredientPrepItem.toLowerCase().includes(searchText.toLowerCase())
    //   );
    // }

    return filtered;
  };

  const isVisible = (timeOfDay: string) => {
    if (selectedTimeOfDay.length === 0) {
      return true;
    }

    if (
      selectedTimeOfDay.findIndex((elem: any) => elem.value === timeOfDay) !==
      -1
    ) {
      return true;
    }

    return false;
  };

  const onBufferChange = (change: number) => {
    setBufferPercentage((prev) => Math.max(prev + change, 0));
  };

  const handleDateChange = (date: Date) => {
    setBufferPercentage(0)
    onDateChange(date)
  }

  return (
    <div className="w-100 mt-2 mx-auto">
      <div className="m-lg-3 mx-0 row t-shade">
        <div className="bg-white">
          <ToolBar
            productionDate={currentDate}
            setProductionDate={handleDateChange}
            hasSearch
            searchText={searchText}
            onSearchTextChange={(val: string) => setSearchText(val)}
            rightAdditionalTools={
              <MultiSelect
                options={TIME_OF_DAY_OPTIONS}
                value={selectedTimeOfDay}
                onChange={setSelectedTimeOfDay}
                labelledBy="Time of Day"
                disableSearch={true}
                shouldToggleOnHover={true}
              />
            }
            leftAdditionalTools={
              (isToday(currentDate) || isFuture(currentDate)) && (
                <Stack spacing={8} alignItems="center">
                  <h4 className='mr-2 mb-0'>Buffer</h4>
                  <Button
                    appearance="primary"
                    onClick={() => onBufferChange(-5)}
                  >
                    {"-5"}
                  </Button>
                  <div
                    style={{
                      width: "50px",
                      borderRadius: "10px",
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >{`${bufferPercentage}%`}</div>
                  <Button
                    appearance="primary"
                    onClick={() => onBufferChange(5)}
                  >
                    {"+5"}
                  </Button>
                </Stack>
              )
            }
          />
          {isVisible("Morning") && (
            <PrepListTable
              data={getFilteredData(TimeOfDay.MORNING)()}
              onChange={onPrepItemChange}
              currentDate={currentDate}
              timeOfDay={TimeOfDay.MORNING}
            />
          )}
          {isVisible("Afternoon") && (
            <PrepListTable
              data={getFilteredData(TimeOfDay.AFTERNOON)()}
              onChange={onPrepItemChange}
              currentDate={currentDate}
              timeOfDay={TimeOfDay.AFTERNOON}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PrepListGrid;
