import * as React from "react";
import { Link } from "react-router-dom";

export default function HomeButton(props: any) {
  const { hbName, hbIcon, hbUrl, size = "default", icon } = props;
  return (
    <React.Fragment>
      <div className="pl-0 pr-lg-3 col-md-4 mb-lg-4 col-lg-3 mb-2 mr-lg-1">
        <div className="card shadow inner text-dark bg-white text-center">
          <div className="">
            {hbIcon && <img className="pt-3 w-75" src={hbIcon} alt="Card cap" />}
            {icon ? icon : null}
          </div>
          <div className="card-body h-auto text-center">
            <h5
              className={
                size !== "default" ? "card-title-ad-pad" : "card-title"
              }
            >
              {hbName}
            </h5>
            <Link to={hbUrl}>
              <button className="btn-block w-75 mx-auto bg-white p-2 rounded b-org nav-org">
                Start
              </button>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
