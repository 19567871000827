import moment from "moment";
import { getHeaders } from "../helpers/helper";

const API = process.env.REACT_APP_AUDIENCE || 3001;


export const buildByDateRange = async (
  fromDate: any,
  toDate: any,
  getAccessTokenSilently: any
) => {
  try {
    const headers = await getHeaders(getAccessTokenSilently);
    const fDate = moment(fromDate).format("YYYY-MM-DD");
    const tDate = moment(toDate).format("YYYY-MM-DD");

    const res = await fetch(
      `${API}api/report/buildtoreport?&fromDate=${fDate}&toDate=${tDate}`,
      {
        headers,
      }
    );
    return (await res) && res.json();
  } catch (error) {
    throw error;
  }
};
export const dailyProductionTimeline = async (
  forDate: any,
  getAccessTokenSilently: any
) => {
  try {
    const headers = await getHeaders(getAccessTokenSilently);
    const fDate = moment(forDate).format("YYYY-MM-DD");
    const res = await fetch(
      `${API}api/report/dailyproductiontimelinereport?&productionDate=${fDate}`,
      {
        headers,
      }
    );
    return (await res) && res.json();
  } catch (error) {
    throw error;
  }
};
export const highVarianceByGroup = async (
  fromDate: any,
  toDate: any,
  getAccessTokenSilently: any
) => {
  try {
    const headers = await getHeaders(getAccessTokenSilently);
    const fDate = moment(fromDate).format("YYYY-MM-DD");
    const tDate = moment(toDate).format("YYYY-MM-DD");
    const res = await fetch(
      `${API}api/report/highvarianceproductNotereport?fromDate=${fDate}&toDate=${tDate}`,
      {
        headers,
      }
    );
    return (await res) && res.json();
  } catch (error) {
    throw error;
  }
};
export const highVarianceByDate = async (
  fromDate: any,
  toDate: any,
  getAccessTokenSilently: any
) => {
  try {
    const headers = await getHeaders(getAccessTokenSilently);
    const fDate = moment(fromDate).format("YYYY-MM-DD");
    const tDate = moment(toDate).format("YYYY-MM-DD");
    const res = await fetch(
      `${API}api/report/highvarianceproductNotereport?fromDate=${fDate}&toDate=${tDate}`,
      {
        headers,
      }
    );
    return (await res) && res.json();
  } catch (error) {
    throw error;
  }
};

export const firstProductProducedByStore = async (
  forDate: any,
  getAccessTokenSilently: any
) => {
  try {
    const headers = await getHeaders(getAccessTokenSilently);
    const fDate = moment(forDate).format("YYYY-MM-DD");
    const res = await fetch(
      `${API}api/report/GetFirstProductProducedByStore?produceDate=${fDate}`,
      {
        headers,
      }
    );
    return (await res) && res.json();
  } catch (error) {
    throw error;
  }
};

export const storesWithNoActuals = async (
  fromDate: any,
  toDate: any,
  getAccessTokenSilently: any
) => {
  try {
    const headers = await getHeaders(getAccessTokenSilently);
    const fDate = moment(fromDate).format("YYYY-MM-DD");
    const tDate = moment(toDate).format("YYYY-MM-DD");

    const res = await fetch(
      `${API}api/report/GetStoreWithNoActuals?&fromDate=${fDate}&toDate=${tDate}`,
      {
        headers,
      }
    );
    return (await res) && res.json();
  } catch (error) {
    throw error;
  }
};

export const StoresWithNoBuildTo = async (
  fromDate: any,
  toDate: any,
  getAccessTokenSilently: any
) => {
  try {
    const headers = await getHeaders(getAccessTokenSilently);
    const fDate = moment(fromDate).format("YYYY-MM-DD");
    const tDate = moment(toDate).format("YYYY-MM-DD");

    const res = await fetch(
      `${API}api/report/GetStoreWithNoBuildTo?&fromDate=${fDate}&toDate=${tDate}`,
      {
        headers,
      }
    );
    return (await res) && res.json();
  } catch (error) {
    throw error;
  }
};

export default {
  buildByDateRange,
  dailyProductionTimeline,
  highVarianceByGroup,
  highVarianceByDate,
  firstProductProducedByStore,
  storesWithNoActuals,
  StoresWithNoBuildTo,
};
