export const color = [
    '#222888',
    '#555780',
    '#890890',
    '#a80200',
    '#aaaeee',
    '#aaafff',
    '#bbbaaa',
    '#bbbbbb',
    '#bbbccc',
    '#bbbddd',
    '#bbbeee',
    '#bbbfff',
    '#cccaaa',
    '#cccbbb',
    '#cccccc',
    '#cccddd',
    '#ccceee',
    '#cccfff',
    '#dddaaa',
    '#dddbbb',
    '#dddccc',
    '#dddddd',
    '#dddeee',
    '#dddfff',
    '#eeeaaa',
    '#eeebbb',
    '#eeeccc',
    '#eeeddd',
    '#eeefff',
    '#fffaaa',
];
export default color;