import * as React from "react";

export default function Header(props: any) {
  return (
    <React.Fragment>
      <h2 className="ml-4 mb-0 font-weight-bold text-left mb-0">
        {props.title}
      </h2>
      <h4 className="ml-4 mb-0 py-2 so-orange">{props.subTitle}</h4>
    </React.Fragment>
  );
}
