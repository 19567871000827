import { useState } from 'react'
import { getHeaders } from '../../../helpers/helper';
import Loader from 'rsuite/Loader';
import axiosInstance from '../../../shared/config/axiosInstance';
import { useAuth0 } from '@auth0/auth0-react';
import Message from 'rsuite/Message'
import { useToaster } from 'rsuite/esm/toaster'
import { AxiosError } from 'axios';
import { GoLinkExternal } from 'react-icons/go'
import './FileGuide.css'

interface Props {
  PLU: string
  iconSize: number
  guideUrl: string
}

const FileGuide = ({ PLU, iconSize, guideUrl }: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const toaster = useToaster();
  const [loading, setLoading] = useState(false)

  const fetchFileGuide = async () => {
    setLoading(true)
    const headers = await getHeaders(getAccessTokenSilently)
    const params = {
      PLU
    }

    axiosInstance.get(`${guideUrl}`, {
      headers,
      params,
      responseType: 'arraybuffer'
    })
    .then(({ data }) => {
      var file = new Blob([data], {type: 'application/pdf'});
      var fileURL = window.URL.createObjectURL(file);
      const windowFeatures = "left=100,top=100,width=500,height=500";
      window.open(fileURL, '_blank', windowFeatures);
    })
    .catch((error: AxiosError) => {
      let message = "Error while fetching File Guide."

      if (error.response?.status === 404) {
        message += " File not found."
      } else {
        message += " Service unavailable."
      }
      toaster.push(
        <Message showIcon type='error' closable>
          {message}
        </Message>,
        { placement: 'topEnd', duration: 3000 }
      )
    })
    .finally(() => {
      setLoading(false)
    })
  }

  if (loading) {
    return (
      <Loader />
    )
  }

  return (
    <div className='so-file-guide'>
      <GoLinkExternal onClick={fetchFileGuide} size={iconSize} />
    </div>
  )
}

export default FileGuide