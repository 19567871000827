import { CSSProperties } from "@material-ui/core/styles/withStyles";

interface ButtonProps {
  isDisabled: boolean;
  colorClass: string;
  onClick: () => void;
  label: string;
  style?: CSSProperties
}

const ActionButtonNew = (props: ButtonProps) => {
  const { isDisabled, colorClass, onClick, label, style } = props;

  return (
    <>
      <button
        style={{
          opacity: isDisabled ? "0.5" : "1",
          maxWidth: "45px",
          ...style
        }}
        className={"py-1 mr-2 mr-md-1 px-1 pointer " + colorClass}
        onClick={!isDisabled ? () => onClick() : () => {}}
      >
        {label}
      </button>
    </>
  );
};

export default ActionButtonNew;
