import React from "react";
import { ProductWeeklyPlan } from "../../shared/models/ProductionPlanning";
import ActionButtonNew from "../common/ActionButtonNew";
import _cloneDeep from 'lodash/cloneDeep'

interface Props {
  product: ProductWeeklyPlan;
  selected?: ProductWeeklyPlan;
  onSelectProduct: (product: ProductWeeklyPlan) => void;
  onChange: (product: ProductWeeklyPlan) => void;
  onSave: () => void;
  disabled: boolean
}

const ProductPlanRow = ({
  product,
  selected,
  onSelectProduct,
  onChange,
  onSave,
  disabled
}: Props) => {
  const getInformationalDivs = () => {
    return (
      <>
        {product.plannedDays.map((day) => (
          <div key={day.dayOfWeek} className="col my-auto text-center">
            <span>{day.plannedQuantity}</span>
            <span className="pl-2 grey">[{day.actualQuantity}]</span>
          </div>
        ))}
        <div className="col"></div>
      </>
    );
  };

  const onProductionChange = (change: number, dayIndex: number) => {
    const updatedProd = _cloneDeep(selected!);
    updatedProd.plannedDays[dayIndex].plannedQuantity = Math.max(
      0,
      updatedProd.plannedDays[dayIndex].plannedQuantity + change
    );
    onChange(updatedProd);
  };

  const setPlannedQuantity = (val: number, dayIndex: number) => {
    const updatedProd = _cloneDeep(selected!)
    updatedProd.plannedDays[dayIndex].plannedQuantity = Math.max(0, val)
    onChange(updatedProd)
  }

  const onResetUpdate = () => {
    const updatedProd = _cloneDeep(product);
    updatedProd.plannedDays = updatedProd.plannedDays.map((elem) => {
      return {
        ...elem,
        plannedQuantity: 0,
      };
    });
    onChange(updatedProd);
  };

  const getActionButtons = () => {
    return (
      <>
        {selected?.plannedDays.map((day, index) => {
          return (
            <div
              key={`button#${day.dayOfWeek}`}
              className="col my-auto text-center d-flex flex-column justify-content-stretch"
            >
              <span className="grey">{`[${day.actualQuantity}]`}</span>
              <ActionButtonNew
                colorClass="ctm-btn-1 col-lg-10 bg-org-1 disp-block"
                style={{ maxWidth: undefined, border: "none" }}
                isDisabled={false}
                label={"+"}
                onClick={() => onProductionChange(1, index)}
              />
              <input
                onChange={e => {
                  const val = parseInt(e.target.value || '0', 10)
                  setPlannedQuantity(val, index)
                }}
                value={day.plannedQuantity}
                className="border"
                type="number"
                min={0}
                style={{
                  width: "83.333333%",
                  height: "2.5rem",
                  textAlign: "center",
                }}
              />
              <ActionButtonNew
                colorClass="ctm-btn-1 col-lg-10 bg-org-1 disp-block"
                style={{ maxWidth: undefined, border: "none" }}
                isDisabled={false}
                label={"-"}
                onClick={() => onProductionChange(-1, index)}
              />
            </div>
          );
        })}
        <div className="col d-flex flex-column justify-content-end">
          <button
            className="bg-success mb-3 mt-1 ctm-btn-1 border rounded md-small"
            onClick={onSave}
          >
            {
              <i className="fa fa-check-circle-o text-success border-success px-2 color-white rounded larger-font"></i>
            }
          </button>
          <button
            className="md-small bg-danger mt-4 ctm-btn-1 border rounded"
            onClick={onResetUpdate}
          >
            <i className="fa fa-times text-danger border-success px-2 color-white rounded larger-font"></i>
          </button>
        </div>
      </>
    );
  };

  return (
    <div
      className="mx-1 row shade-row my-0 py-3 rounded mt-3"
      onClick={() => disabled ? null : onSelectProduct(product)}
    >
      <div className="col-lg-3 col my-auto">
        <p className="mb-0 md-small">{product.productName}</p>
      </div>
      {selected && selected.productId === product.productId && !disabled
        ? getActionButtons()
        : getInformationalDivs()}
    </div>
  );
};

export default ProductPlanRow;
