import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { getHeaders } from "../../../helpers/helper";
import axiosInstance from "../../../shared/config/axiosInstance";
import { ITakeHomeSpecial } from "../../../shared/models/TakeHomeSpecial";
import { MutationKeys } from "../../../shared/react-query/mutationKeys";

async function updateTakeHomeSpecials(
  updatedProduction: ITakeHomeSpecial[],
  getAccessTokenSilently: any
) {
  const headers = await getHeaders(getAccessTokenSilently);
  const { data } = await axiosInstance.post(
    "/takehomespecial",
    updatedProduction,
    {
      headers: { ...headers, "Content-Type": "application/json" },
    }
  );
  return data;
}

export function useUpdateTakeHomeSpecials() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(
    (data: ITakeHomeSpecial[]) =>
      updateTakeHomeSpecials(data, getAccessTokenSilently),
    {
      mutationKey: [MutationKeys.TAKE_HOME],
    }
  );
}
