import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { getHeaders } from "../../../helpers/helper";
import axiosInstance from "../../../shared/config/axiosInstance";

interface ApiParams {
  planWeek: number;
  planYear: number;
}

async function deleteWeekPlan(params: ApiParams, getAccessTokenSilently: any) {
  const headers = await getHeaders(getAccessTokenSilently);
  const { data } = await axiosInstance.delete("/productionplanning", {
    headers: headers,
    params: params,
  });
  return data;
}

export function useDeletePlanning() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation((params: ApiParams) =>
    deleteWeekPlan(params, getAccessTokenSilently)
  );
}
