import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { getHeaders } from "../../../helpers/helper";
import axiosInstance from "../../../shared/config/axiosInstance";
import { ITakeHomeSpecial } from "../../../shared/models/TakeHomeSpecial";

async function updateTakeHomeNotes(
  updatedTakeHomeSpecial: ITakeHomeSpecial,
  getAccessTokenSilently: any
) {
  const headers = await getHeaders(getAccessTokenSilently);
  const { data } = await axiosInstance.post(
    "/takehomespecial/SaveNotes",
    updatedTakeHomeSpecial,
    {
      headers: { ...headers, "Content-Type": "application/json" },
    }
  );
  return data;
}

export function useUpdateTakeHomeNotes() {
    const { getAccessTokenSilently } = useAuth0();

  return useMutation((data: ITakeHomeSpecial) => updateTakeHomeNotes(data, getAccessTokenSilently))
}