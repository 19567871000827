import DatePicker from 'rsuite/DatePicker'
import './WeekPicker.css'

interface Props {
  placeholder?: React.ReactNode
  value?: Date | null
  handleChange?: (date: Date | null) => void
  shouldDisableDate?: (date: Date) => boolean
}

const WeekPicker = (props: Props) => {
  
  return (
      <DatePicker
        placeholder={props.placeholder}
        isoWeek
        showWeekNumbers
        value={props.value}
        onChange={props.handleChange}
        shouldDisableDate={props.shouldDisableDate}
      />
  )
}

export default WeekPicker
