export interface PrepItem {
  description: string;
  UOM: string
  prepGuide: string;
}

export interface PrepIngredients {
  ingredient: string;
  prepItems: PrepItem[]
}

export interface Prep {
  prepGroup: string;
  prepIngredients: PrepIngredients[]
}

export interface PrepTime {
  timeOfDay: string;
  prep: Prep[]
}

export interface PrepList {
  prepTime: PrepTime[]
}

export interface PrepArticle {
  storeId: string;
  month: string;
  prepDate: string;
  timeOfDay: "Morning" | "Afternoon";
  ingredient: string;
  prepItem: string;
  ingredientPrepItem: string;
  prepGuide: string;
  quantity: number;
  uoM: string;
  completedTime: string  | null;
  notes: string;
}

export enum TimeOfDay {
  MORNING = 'Morning',
  AFTERNOON = 'Afternoon'
}
