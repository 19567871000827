import { useEffect, useRef, useState } from "react";
import _, { debounce } from "lodash";
import { Product } from "../../shared/models/Product";
import { useProduction } from "./hooks/useProduction";
import { useUpdateProduction } from "./hooks/useUpdateProduction";
import { getUserStoreNameFromLocalStorage } from "../../helpers/localStorageHelper";
import CategoryFilterButtons from "../common/CategoryFilterButtons";
import Header from "../common/Header";
import { ZERO_ID } from "../../shared/constants/constants";
import { useIsMutating } from "@tanstack/react-query";
import { MutationKeys } from "../../shared/react-query/mutationKeys";
import ProductionGrid from "./components/ProductionGrid";

const isFresh = (product: Product) => {
  return product.id === ZERO_ID;
};

const containsFresh = (products: Product[]) => {
  const index = products.findIndex((product) => isFresh(product));
  if (index !== -1) {
    return true;
  }
  return false;
};

const Production = () => {
  const { data, productionDate, setProductionDate } = useProduction();
  const [productionData, setProductionData] = useState<Product[]>([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showTabs, setShowTabs] = useState(true);

  const pendingRequests = useRef<{ [key: string]: Product }>({}).current;

  const { mutate: updateProduction, data: mutationResult } =
    useUpdateProduction();
  const isUpdatingProduction = useIsMutating([MutationKeys.PRODUCTION]);

  const debouncedSave = useRef(
    debounce((changedProducts: Product[]) => {
      updateProduction(changedProducts); // func that makes the API call
      changedProducts.forEach((product) => (product.isDirty = false));
    }, 1000)
  ).current;

  useEffect(() => {
    if (_.isEmpty(data)) {
      return;
    }
    setProductionData(data);
  }, [data]);

  useEffect(() => {
    const changedProducts = productionData.filter((product) => product.isDirty);

    // if changedProducts contain a product with zero id. then do not make POST call until an ongoing mutation finishes
    if (containsFresh(changedProducts) && isUpdatingProduction > 0) {
      changedProducts.forEach((product) => {
        pendingRequests[product.productId] = product;
      });
      console.log(`blocking req for data: `, changedProducts);
      return;
    }

    if (changedProducts.length === 0) {
      return;
    }
    debouncedSave(changedProducts);
  }, [productionData]);

  useEffect(() => {
    if (!mutationResult) {
      return;
    }

    const updatedRows = [...mutationResult] as Product[];

    let updateIdsForRows: Product[] = [];
    let updateLocalState = false;

    updatedRows.forEach((product) => {
      if (product.productId in pendingRequests) {
        updateLocalState = true;
        updateIdsForRows.push({
          ...pendingRequests[product.productId],
          id: product.id,
          isDirty: true,
        });
        delete pendingRequests[product.productId];
      }
    });

    // create filtered data
    if (!updateLocalState) {
      return;
    }

    console.log(`updating local state for new ids`);
    const newProductionData = [...productionData];
    updateIdsForRows.forEach((updated) => {
      const index = newProductionData.findIndex(
        (product) => product.productId === updated.productId
      );
      if (index !== -1) {
        newProductionData[index] = updated;
      }
    });
    setProductionData(newProductionData);
  }, [mutationResult]);

  const onProductChange = (newProduct: Product) => {
    const newData = [...productionData];
    const index = newData.findIndex(
      (product) => product.productId === newProduct.productId
    );

    if (index === -1) {
      return;
    }

    if (isFresh(newProduct)) {
      pendingRequests[newProduct.productId] = newProduct;
    }

    newData[index] = newProduct;

    setProductionData(newData);
  };

  const onSelectCategory = (categoryName: string) => {
    if (selectedCategory === categoryName) {
      setSelectedCategory("");
    } else {
      setSelectedCategory(categoryName);
    }
  };

  var userStoreName = getUserStoreNameFromLocalStorage();
  const headerObject = {
    title: `SOUL ORIGIN (${userStoreName})`,
    subTitle: "Daily Production",
  };

  return (
    <div className="container-fluid mt-md-3 text-left">
      <Header {...headerObject} />
      <CategoryFilterButtons
        showTabs={showTabs}
        onShowTabsChange={() => setShowTabs(!showTabs)}
        selectedCategory={selectedCategory}
        onCategoryChange={onSelectCategory}
      />
      <ProductionGrid
        productionData={productionData}
        onProductChange={onProductChange}
        prodDate={productionDate}
        setProdDate={(newDate: Date) => setProductionDate(newDate)}
        selectedCategory={selectedCategory}
      />
    </div>
  );
};

export default Production;
