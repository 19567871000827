import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { getHeaders } from "../../../helpers/helper";
import axiosInstance from "../../../shared/config/axiosInstance";
import { Product } from "../../../shared/models/Product";

async function updateProductNotes(
  updatedProduction: Product,
  getAccessTokenSilently: any
) {
  const headers = await getHeaders(getAccessTokenSilently);
  const { data } = await axiosInstance.post(
    "/producestock/SaveNotes",
    updatedProduction,
    {
      headers: { ...headers, "Content-Type": "application/json" },
    }
  );
  return data;
}

export function useUpdateProductNotes() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation((data: Product) =>
    updateProductNotes(data, getAccessTokenSilently)
  );
}
