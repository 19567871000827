import { PrepArticle, TimeOfDay } from "../../shared/models/PrepList";
import { PrepGroupedByIngredient } from "./PrepListGrid";
import { addDays, format } from "date-fns";
import PrepPanel from "./PrepPanel";

interface Props {
  data?: PrepGroupedByIngredient;
  onChange: (item: PrepArticle, successCb?: () => void, errorCb?: () => void) => void;
  currentDate: Date;
  timeOfDay: TimeOfDay
}

const getHeaderMessage = (timeOfDay: "Morning" | "Afternoon", currentDate: Date) => {
  if (timeOfDay === 'Morning') {
    return `(Prep for build date - ${format(currentDate, 'EEE, d MMM, yyyy')})`
  }
  
  return `(Prep for build date - ${format(addDays(currentDate, 1), 'EEE, d MMM, yyyy')})`
}

const PrepListTable = ({ data, onChange, currentDate, timeOfDay }: Props) => {
  return (
    <div className="w-100 mb-4">
      <div className="mx-1 bg-header my-0 py-3 rounded">
        <div className="row">
          <div className="col">
            <h4 
              className="pb-1 pl-2 mb-1"
              style={{ borderBottom: '1px solid #ddd' }}
            >
              <span>{timeOfDay.toUpperCase()}</span>
              <span className='ml-2'>{getHeaderMessage(timeOfDay, currentDate)}</span>
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-4 text-center">
            <h5 className="mb-0">ITEM</h5>
          </div>
          <div className="col-2 text-center">
            <h5 className="mb-0">QUANTITY</h5>
          </div>
          <div className="col-1 text-center">
            <h5 className="mb-0">UNIT</h5>
          </div>
          <div className="col-2 text-center">
            <h5 className="mb-0">DONE</h5>
          </div>
          <div className="col-1 text-center">
            <h5 className="mb-0">GUIDE</h5>
          </div>
          <div className="col-2 text-center">
            <h5 className="mb-0">NOTES</h5>
          </div>
        </div>
      </div>
      <div>
        {data
          ? Object.keys(data).filter(key => data[key].length).map(ingredient => (
            <PrepPanel
              currentDate={currentDate}
              ingredient={ingredient}
              items={data[ingredient]}
              onChange={onChange}
              key={`prep-group-${ingredient}`}
            />
          ))
          : null}
      </div>
    </div>
  );
};

export default PrepListTable;
