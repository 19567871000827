import { useEffect, useState } from "react";
import Header from "../common/Header";
import NotFound from "../common/NotFound";
import { Loading } from "../Loading";
import { getUserStoreNameFromLocalStorage } from "../../helpers/localStorageHelper";
import FilterControls from "./FilterControls";
import { useCateringOrders } from "./hooks/useCateringOrders";
import SummaryView from "./SummaryView";
import DetailView from "./DetailView";
import { sortArray, SortKeys } from "./utils/sortCateringOrder";

const Catering = () => {
  const { data, isLoading, fromDate, toDate, setFromDate, setToDate } =
    useCateringOrders();
  const [cateringData, setCateringData] = useState<any[]>([]);
  const [sortValue, setSortValue] = useState<SortKeys>(
    SortKeys.FULFILLMENT_DATE
  );
  const [sortAscending, setSortAscending] = useState(true);
  const [showSummary, setShowSummary] = useState(false);

  useEffect(() => {
    if (!data) {
      return;
    }

    const sortedData = sortArray(data, SortKeys.FULFILLMENT_DATE, true);
    setCateringData(sortedData);
  }, [data]);

  useEffect(() => {
    const sortedData = sortArray(cateringData, sortValue, sortAscending);

    let isSameSortOrder = true;
    sortedData.forEach((order, index) => {
      if (order.id !== cateringData[index].id) {
        isSameSortOrder = false;
      }
    });

    if (isSameSortOrder) {
      return;
    }
    setCateringData(sortedData);
  }, [sortValue, sortAscending, cateringData]);

  const toggleSortDirection = () => {
    setSortAscending((prevVal) => {
      return !prevVal;
    });
  };

  const toggleSummaryView = () => {
    setShowSummary((prev) => !prev);
  };

  const setSortKey = (val: SortKeys) => {
    setSortValue(val);
  };

  var userStoreName = getUserStoreNameFromLocalStorage();
  const headerObject = {
    title: `SOUL ORIGIN (${userStoreName})`,
    subTitle: "Catering",
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="ml-lg-3 text-left page-container">
        <Header {...headerObject} />
        <div className="ml-4 order-grid t-shade">
          <FilterControls
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            sortValue={sortValue}
            onChangeSortValue={setSortKey}
            sortAscending={sortAscending}
            toggleSortDirection={toggleSortDirection}
            showSummaryView={showSummary}
            toggleSummaryView={toggleSummaryView}
          />

          <div className="col order-list">
            {cateringData.length > 0 ? (
              showSummary ? (
                <SummaryView
                  cateringOrders={cateringData}
                  fromDate={fromDate}
                  toDate={toDate}
                />
              ) : (
                <DetailView cateringData={cateringData} />
              )
            ) : (
              <NotFound />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Catering;
