import React from "react";
import reportIcon from "../assets/images/Assets/reports.png";
import HomeButton from "./common/HomeButton";
import Header from "./common/Header";
import { NonAdminRedirect } from "../helpers/helper";
import { getUserStoreNameFromLocalStorage } from "../helpers/localStorageHelper";

export function AdminHome() {
  NonAdminRedirect();
  var userStoreName = getUserStoreNameFromLocalStorage();
  const headerObject = {
    title: `SOUL ORIGIN (${userStoreName})`,
    subTitle: "Head Office Reports",
  };

  return (
    <>
      <div className="row justify-content-space-around pr-lg-4 ml-lg-5">
        <Header {...headerObject} />
        <div className="row justify-content-space-around pr-lg-4 my-lg-5">
          <HomeButton
            hbIcon={reportIcon}
            hbName={"Product Produced By Store"}
            hbUrl={"/HeadOffice/ProductProducedByStore"}
          />
          <HomeButton
            hbIcon={reportIcon}
            hbName={"Stores With No Build-To"}
            hbUrl={"/HeadOffice/StoreWithNoBuildTo"}
          />
          <HomeButton
            hbIcon={reportIcon}
            hbName={"Stores With No Actuals"}
            hbUrl={"/HeadOffice/StoreWithNoActuals"}
          />
        </div>
      </div>
    </>
  );
}
