import { ProductCategories } from "../../shared/constants/constants";
import ButtonGroup from "./ButtonGroup";

interface Props {
  showTabs: boolean;
  onShowTabsChange: () => void;
  selectedCategory: string;
  onCategoryChange: (newCategory: string) => void;
}

const CategoryFilterButtons = ({
  onCategoryChange,
  onShowTabsChange,
  selectedCategory,
  showTabs,
}: Props) => {
  return (
    <>
      <div className="py-3 text-right">
        <button
          onClick={onShowTabsChange}
          className="bg-white rounded-circle shade border-0"
        >
          <i
            className={`xl px-1 py-1 fa ${
              showTabs ? "fa-angle-up" : "fa-angle-down"
            }`}
          ></i>
        </button>
      </div>
      {showTabs && (
        <ButtonGroup
          buttons={ProductCategories}
          onChangeBtnClick={onCategoryChange}
          showValue={selectedCategory}
        />
      )}
    </>
  );
};

export default CategoryFilterButtons;
