import { endOfISOWeek, format, getISOWeek, startOfISOWeek } from "date-fns";
import './WeekInfo.css'

interface Props { 
  date: Date
  className?: string
}

const WeekInfo = ({ date, className }: Props) => {
  return (
    <div className={`so-week-info ${className}`}>
      <div>
        <span>Week Number: </span>
        <span>{getISOWeek(date)}</span>
      </div>
      <div>
        <span>Start of Week: </span>
        <span>{format(startOfISOWeek(date), "dd/MM/yyyy")}</span>
      </div>
      <div>
        <span>End of Week: </span>
        <span>{format(endOfISOWeek(date), "dd/MM/yyyy")}</span>
      </div>
    </div>
  );
}

export default WeekInfo